<template>
    <fragment>
        <div
            @click="$emit('hide')"
            class="overlay active"
        >
            <div
                @click.stop
                :class="'modal modal--' + internalSize"
                :style="{overflow: $store.state.iModalCount === iModalCount ? 'auto' : 'hidden'}"
            >
                <div
                    class="close modal__close"
                    @click="$emit('hide')"
                >
                    <svg
                        class="close__cross"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 13L13 1.00001"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                        <path
                            d="M1 1L13 13"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                    </svg>
                </div>

                <div class="modal__content">
                    <slot />
                </div>
            </div>
        </div>
    </fragment>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                iModalCount: 0,

                internalSize: 'big',
                arAllowSizes: [
                    'small',
                    'big',
                ],
            };
        },
        created() {
            this.$store.commit('disableScrollBody');

            this.iModalCount = this.$store.state.iModalCount;

            if (this.arAllowSizes.indexOf(this.size) !== -1) {
                this.internalSize = this.size;
            }
        },
        destroyed() {
            this.$store.commit('enableScrollBody');
        },
    };
</script>
