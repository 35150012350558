import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';

import VacationRoutes from './routes';
import VacationI18nRu from './lang/ru';
import VacationI18nEn from './lang/en';
import VacationEndpoints from './endpoints';

const moduleName = 'structure';

export default {
    install: (Vue, options) => {
        if (options.router instanceof VueRouter) {
            options.router.addRoutes(VacationRoutes);
        }

        if (options.i18n instanceof VueI18n) {
            const i18nRu = [];
            i18nRu[moduleName] = VacationI18nRu;

            const i18nEn = [];
            i18nEn[moduleName] = VacationI18nEn;

            options.i18n.mergeLocaleMessage('ru', i18nRu);
            options.i18n.mergeLocaleMessage('en', i18nEn);
        }

        if (options.api instanceof Vue.prototype.$Api.constructor) {
            options.api.addEndpoints(moduleName, VacationEndpoints);
        }
    },
};
