import Vue from 'vue';

const StructureUnits = () => import('./views/structure/units');
const StructureUnitAdd = () => import('./views/structure/units/add');
const StructureUnitEdit = () => import('./views/structure/units/edit');

const Persons = () => import('./views/structure/persons');
const PersonAdd = () => import('./views/structure/persons/add');
const PersonEdit = () => import('./views/structure/persons/edit');
const PersonsImport = () => import('./views/structure/persons/import');

import UnitsImport from './views/structure/units/import';

function isAdmin() {
    return Vue.prototype.$Auth.isAdmin();
}

const routes = [
    {
        path: '/structure/units',
        name: 'structure.structure',
        components: {
            default: StructureUnits,
        },
        meta: {
            title: 'structure.company_structure_units',
            middlewareAuth: true,
        },
    },
    {
        path: '/structure/units/add',
        name: 'structure_add',
        components: {
            default: StructureUnitAdd,
        },
        meta: {
            title: 'structure.add',
            middlewareAuth: true,
            checkAccessFunctions: {
                isAdmin,
            },
        },
    },
    {
        path: '/structure/units/edit/:id',
        name: 'structure_edit',
        components: {
            default: StructureUnitEdit,
        },
        meta: {
            title: 'structure.edit',
            middlewareAuth: true,
            checkAccessFunctions: {
                isAdmin,
            },
        },
    },
    {
        path: '/structure/units/import',
        name: 'units_import',
        components: {
            default: UnitsImport,
        },
        meta: {
            title: 'structure.import_units',
            middlewareAuth: true,
            checkAccessFunctions: {
                isAdmin,
            },
        },
    },
    {
        path: '/structure/persons',
        name: 'structure.persons',
        components: {
            default: Persons,
        },
        meta: {
            title: 'structure.persons',
            middlewareAuth: true,
            checkAccessFunctions: {
                isAdmin,
            },
        },
    },
    {
        path: '/structure/persons/add',
        name: 'person_add',
        components: {
            default: PersonAdd,
        },
        meta: {
            title: 'structure.add_new_person',
            middlewareAuth: true,
            checkAccessFunctions: {
                isAdmin,
            },
        },
    },
    {
        path: '/structure/persons/edit/:user_id',
        name: 'person_edit',
        components: {
            default: PersonEdit,
        },
        meta: {
            title: 'structure.edit_person',
            middlewareAuth: true,
            checkAccessFunctions: {
                isAdmin,
            },
        },
    },
    {
        path: '/structure/persons/import',
        name: 'persons_import',
        components: {
            default: PersonsImport,
        },
        meta: {
            title: 'structure.import_persons',
            middlewareAuth: true,
            checkAccessFunctions: {
                isAdmin,
            },
        },
    },
];

export default routes;
