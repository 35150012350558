const endpoints = [
    {
        name: 'getMyVacations',
        method: 'get',
        path: '/vacations/my',
    },
    {
        name: 'getMyVacation',
        method: 'get',
        path: '/vacations/my/:id',
    },
    {
        name: 'getMyOrder',
        method: 'get',
        path: '/vacations/my/orders/:id',
    },
    {
        name: 'getMyStatement',
        method: 'get',
        path: '/vacations/my/statements/:id',
    },
    {
        name: 'getMyVacationDates',
        method: 'get',
        path: '/vacations/my/vacation-dates',
    },
    {
        name: 'addMyVacation',
        method: 'post',
        path: '/vacations/my/add',
    },
    {
        name: 'updateMyVacation',
        method: 'post',
        path: '/vacations/my/:id',
    },
    {
        name: 'cancelMyVacation',
        method: 'post',
        path: '/vacations/my/:id/cancel',
    },
    {
        name: 'getMyAlerts',
        method: 'get',
        path: '/vacations/my/alerts',
    },
    {
        name: 'getMyReport',
        method: 'get',
        path: '/vacations/my/report',
    },
    {
        name: 'acceptRecall',
        method: 'post',
        path: '/vacations/my/:id/recall/accept',
    },
    {
        name: 'rejectRecall',
        method: 'post',
        path: '/vacations/my/:id/recall/reject',
    },
    {
        name: 'getMyRecalls',
        method: 'get',
        path: '/vacations/my/recalls',
    },
    {
        name: 'getSubordinatesRecalls',
        method: 'get',
        path: '/vacations/subordinates/recalls',
    },
    {
        name: 'getHrRecalls',
        method: 'get',
        path: '/vacations/hr/recalls',
    },
    {
        name: 'getMyTransfers',
        method: 'get',
        path: '/vacations/my/transfers',
    },
    {
        name: 'downloadMyVacationDocument',
        method: 'get',
        path: '/vacations/my/:vacation_id/documents/:document_id/data',
    },
    {
        name: 'getSubordinatesTransfers',
        method: 'get',
        path: '/vacations/subordinates/transfers',
    },
    {
        name: 'getHrTransfers',
        method: 'get',
        path: '/vacations/hr/transfers',
    },
    {
        name: 'transferMyVacation',
        method: 'post',
        path: '/vacations/my/:id/transfer',
    },
    {
        name: 'transferMyVacationToNextYear',
        method: 'post',
        path: '/vacations/my/:id/transfer/next-year',
    },
    {
        name: 'cancelTransferMyVacation',
        method: 'post',
        path: '/vacations/my/:transfer_id/transfer/cancel',
    },
    {
        name: 'getMyVacationStatement',
        method: 'get',
        path: '/vacations/my/:id/statement',
    },
    {
        name: 'getMyVacationOrder',
        method: 'get',
        path: '/vacations/my/:id/order',
    },
    {
        name: 'getMyVacationRecallStatement',
        method: 'get',
        path: '/vacations/my/recalls/:recall_id/statement',
    },
    {
        name: 'getMyVacationRecallOrder',
        method: 'get',
        path: '/vacations/my/recalls/:recall_id/order',
    },
    {
        name: 'getMyVacationTransferStatement',
        method: 'get',
        path: '/vacations/my/transfers/:transfer_id/statement',
    },
    {
        name: 'getMyVacationTransferOrder',
        method: 'get',
        path: '/vacations/my/transfers/:transfer_id/order',
    },

    //--------------------------------------------------

    {
        name: 'getSubordinatesVacationDates',
        method: 'get',
        path: '/vacations/subordinates/:user_id/vacation-dates',
    },
    {
        name: 'getSubordinatesVacations',
        method: 'get',
        path: '/vacations/subordinates/',
    },
    {
        name: 'getSubordinatesVacation',
        method: 'get',
        path: '/vacations/subordinates/:id',
    },
    {
        name: 'addSubordinatesVacation',
        method: 'post',
        path: '/vacations/subordinates/add',
    },
    {
        name: 'acceptSubordinatesVacation',
        method: 'post',
        path: '/vacations/subordinates/:id/accept',
    },
    {
        name: 'rejectSubordinatesVacation',
        method: 'post',
        path: '/vacations/subordinates/:id/reject',
    },
    {
        name: 'bulkAcceptSubordinatesVacation',
        method: 'post',
        path: '/vacations/subordinates/bulk-action/accept',
    },
    {
        name: 'bulkRejectSubordinatesVacation',
        method: 'post',
        path: '/vacations/subordinates/bulk-action/reject',
    },
    {
        name: 'cancelSubordinatesRecall',
        method: 'post',
        path: '/vacations/subordinates/:vacation_recall_id/cancel',
    },
    {
        name: 'getSubordinatesAlerts',
        method: 'get',
        path: '/vacations/subordinates/alerts',
    },
    {
        name: 'downloadSubordinatesVacationDocument',
        method: 'get',
        path: '/vacations/subordinates/:vacation_id/documents/:document_id/data',
    },
    {
        name: 'getSubordinatesReport',
        method: 'get',
        path: '/vacations/subordinates/report',
    },
    {
        name: 'recallRequest',
        method: 'post',
        path: '/vacations/subordinates/:id/recall',
    },
    {
        name: 'acceptSubordinatesTransferVacation',
        method: 'post',
        path: '/vacations/subordinates/:transfer_id/transfer/accept',
    },
    {
        name: 'rejectSubordinatesTransferVacation',
        method: 'post',
        path: '/vacations/subordinates/:transfer_id/transfer/reject',
    },
    {
        name: 'getSubordinatesVacationRecallStatement',
        method: 'get',
        path: '/vacations/subordinates/recalls/:recall_id/statement',
    },
    {
        name: 'getSubordinatesVacationRecallOrder',
        method: 'get',
        path: '/vacations/subordinates/recalls/:recall_id/order',
    },
    {
        name: 'getSubordinatesVacationStatement',
        method: 'get',
        path: '/vacations/subordinates/:id/statement',
    },
    {
        name: 'getSubordinatesVacationOrder',
        method: 'get',
        path: '/vacations/subordinates/:id/order',
    },
    {
        name: 'getSubordinatesVacationTransferStatement',
        method: 'get',
        path: '/vacations/subordinates/transfers/:transfer_id/statement',
    },
    {
        name: 'getSubordinatesVacationTransferOrder',
        method: 'get',
        path: '/vacations/subordinates/transfers/:transfer_id/order',
    },

    //--------------------------------------------------

    {
        name: 'getHrVacationDates',
        method: 'get',
        path: '/vacations/hr/:user_id/vacation-dates',
    },
    {
        name: 'getHrVacations',
        method: 'get',
        path: '/vacations/hr/',
    },
    {
        name: 'getHrVacation',
        method: 'get',
        path: '/vacations/hr/:id',
    },
    {
        name: 'addHrVacation',
        method: 'post',
        path: '/vacations/hr/add',
    },
    {
        name: 'updateHrVacation',
        method: 'post',
        path: '/vacations/hr/:id',
    },
    {
        name: 'acceptHrVacation',
        method: 'post',
        path: '/vacations/hr/:id/accept',
    },
    {
        name: 'rejectHrVacation',
        method: 'post',
        path: '/vacations/hr/:id/reject',
    },
    {
        name: 'cancelHrVacation',
        method: 'post',
        path: '/vacations/hr/:id/cancel',
    },
    {
        name: 'bulkAcceptHrVacation',
        method: 'post',
        path: '/vacations/hr/bulk-action/accept',
    },
    {
        name: 'bulkRejectHrVacation',
        method: 'post',
        path: '/vacations/hr/bulk-action/reject',
    },
    {
        name: 'getHrAlerts',
        method: 'get',
        path: '/vacations/hr/alerts',
    },
    {
        name: 'getHrReport',
        method: 'get',
        path: '/vacations/hr/report',
    },
    {
        name: 'acceptHrTransferVacation',
        method: 'post',
        path: '/vacations/hr/:transfer_id/transfer/accept',
    },
    {
        name: 'rejectHrTransferVacation',
        method: 'post',
        path: '/vacations/hr/:transfer_id/transfer/reject',
    },
    {
        name: 'transferHrVacation',
        method: 'post',
        path: '/vacations/hr/:id/transfer',
    },
    {
        name: 'transferHrVacationToNextYear',
        method: 'post',
        path: '/vacations/hr/:id/transfer/next-year',
    },
    {
        name: 'downloadHrVacationDocument',
        method: 'get',
        path: '/vacations/hr/:vacation_id/documents/:document_id/data',
    },
    {
        name: 'getHrVacationStatement',
        method: 'get',
        path: '/vacations/hr/:id/statement',
    },
    {
        name: 'getHrVacationOrder',
        method: 'get',
        path: '/vacations/hr/:id/order',
    },
    {
        name: 'getHrVacationRecallStatement',
        method: 'get',
        path: '/vacations/hr/recalls/:recall_id/statement',
    },
    {
        name: 'getHrVacationRecallOrder',
        method: 'get',
        path: '/vacations/hr/recalls/:recall_id/order',
    },
    {
        name: 'getHrVacationTransferStatement',
        method: 'get',
        path: '/vacations/hr/transfers/:transfer_id/statement',
    },
    {
        name: 'getHrVacationTransferOrder',
        method: 'get',
        path: '/vacations/hr/transfers/:transfer_id/order',
    },

    //--------------------------------------------------

    {
        name: 'getMyPlanVacations',
        method: 'get',
        path: '/vacations/plan/my',
    },
    {
        name: 'getMyPlanVacation',
        method: 'get',
        path: '/vacations/plan/my/:plan_id/vacations/:vacation_id',
    },
    {
        name: 'addMyPlanVacation',
        method: 'post',
        path: '/vacations/plan/my/:plan_id/vacations',
    },
    {
        name: 'updateMyPlanVacation',
        method: 'patch',
        path: '/vacations/plan/my/:plan_id/vacations/:vacation_id',
    },
    {
        name: 'getMyPlanHistory',
        method: 'get',
        path: '/vacations/plan/my/:plan_id/history',
    },
    {
        name: 'completeMyPlan',
        method: 'post',
        path: '/vacations/plan/my/:plan_id/complete',
    },
    {
        name: 'deleteMyPlanVacation',
        method: 'delete',
        path: '/vacations/plan/my/:plan_id/vacations/:vacation_id',
    },
    {
        name: 'renewPlan',
        method: 'get',
        path: '/vacations/plan/my/:plan_id/renew',
    },
    {
        name: 'getMyPlanReport',
        method: 'get',
        path: '/vacations/plan/my/report',
    },
    {
        name: 'getMyPlanAlerts',
        method: 'get',
        path: '/vacations/plan/my/:plan_id/vacations/alerts',
    },

    //--------------------------------------------------

    {
        name: 'getSubordinatesPlans',
        method: 'get',
        path: '/vacations/plan/subordinates',
    },
    {
        name: 'getSubordinatesPlan',
        method: 'get',
        path: '/vacations/plan/subordinates/:plan_id',
    },
    {
        name: 'getSubordinatesPlanVacation',
        method: 'get',
        path: '/vacations/plan/subordinates/:plan_id/vacations/:vacation_id',
    },
    {
        name: 'getSubordinatesPlanHistory',
        method: 'get',
        path: '/vacations/plan/subordinates/:plan_id/history',
    },
    {
        name: 'getSubordinatesPlanStatistics',
        method: 'get',
        path: '/vacations/plan/subordinates/statistics',
    },
    {
        name: 'acceptSubordinatesPlan',
        method: 'post',
        path: '/vacations/plan/subordinates/:id/accept',
    },
    {
        name: 'acceptSubordinatesPlans',
        method: 'post',
        path: '/vacations/plan/subordinates/accept',
    },
    {
        name: 'rejectSubordinatesPlan',
        method: 'post',
        path: '/vacations/plan/subordinates/:id/reject',
    },
    {
        name: 'rejectSubordinatesPlans',
        method: 'post',
        path: '/vacations/plan/subordinates/reject',
    },
    {
        name: 'getSubordinatesPlanReport',
        method: 'get',
        path: '/vacations/plan/subordinates/report',
    },
    {
        name: 'getSubordinatesPlanScheduleReport',
        method: 'get',
        path: '/vacations/plan/subordinates/schedule/report',
    },
    {
        name: 'getSubordinatesPlanSchedule',
        method: 'get',
        path: '/vacations/plan/subordinates/schedule',
    },

    //--------------------------------------------------

    {
        name: 'getHrPlans',
        method: 'get',
        path: '/vacations/plan/hr',
    },
    {
        name: 'getHrPlan',
        method: 'get',
        path: '/vacations/plan/hr/:plan_id',
    },
    {
        name: 'addHrPlanVacation',
        method: 'post',
        path: '/vacations/plan/hr/:plan_id/vacations',
    },
    {
        name: 'getHrPlanVacation',
        method: 'get',
        path: '/vacations/plan/hr/:plan_id/vacations/:vacation_id',
    },
    {
        name: 'deleteHrPlanVacation',
        method: 'delete',
        path: '/vacations/plan/hr/:plan_id/vacations/:vacation_id',
    },
    {
        name: 'updateHrPlanVacation',
        method: 'patch',
        path: '/vacations/plan/hr/:plan_id/vacations/:vacation_id',
    },
    {
        name: 'getHrPlanHistory',
        method: 'get',
        path: '/vacations/plan/hr/:plan_id/history',
    },
    {
        name: 'getHrPlanStatistics',
        method: 'get',
        path: '/vacations/plan/hr/statistics',
    },
    {
        name: 'acceptHrPlan',
        method: 'post',
        path: '/vacations/plan/hr/:id/accept',
    },
    {
        name: 'acceptHrPlans',
        method: 'post',
        path: '/vacations/plan/hr/accept',
    },
    {
        name: 'rejectHrPlan',
        method: 'post',
        path: '/vacations/plan/hr/:id/reject',
    },
    {
        name: 'rejectHrPlans',
        method: 'post',
        path: '/vacations/plan/hr/reject',
    },
    {
        name: 'getHrPlanReport',
        method: 'get',
        path: '/vacations/plan/hr/report',
    },
    {
        name: 'getHrPlanSchedule',
        method: 'get',
        path: '/vacations/plan/hr/schedule',
    },
    {
        name: 'getHrPlanAlerts',
        method: 'get',
        path: '/vacations/plan/hr/:plan_id/vacations/alerts',
    },
    {
        name: 'getHrPlanT7Report',
        method: 'get',
        path: '/vacations/plan/hr/t7-report',
    },
    {
        name: 'getHrPlanScheduleReport',
        method: 'get',
        path: '/vacations/plan/hr/schedule/report',
    },

    //--------------------------------------------------

    {
        name: 'getBalances',
        method: 'get',
        path: '/vacations/balances',
    },
    {
        name: 'getStatuses',
        method: 'get',
        path: '/vacations/statuses',
    },
    {
        name: 'getPlanStatuses',
        method: 'get',
        path: '/vacations/plan/statuses',
    },
    {
        name: 'getRecallStatuses',
        method: 'get',
        path: '/vacations/recalls/statuses',
    },
    {
        name: 'getTransferStatuses',
        method: 'get',
        path: '/vacations/transfers/statuses',
    },
    {
        name: 'getPlanState',
        method: 'get',
        path: '/vacations/plan/state',
    },
    {
        name: 'getTypes',
        method: 'get',
        path: '/vacations/types',
    },
    // {
    //     name: 'getT7Report',
    //     method: 'get',
    //     path: '/vacations/t7-report',
    // },
    {
        name: 'getAbsenceSchedule',
        method: 'get',
        path: '/vacations/absence/schedule',
    },
    {
        name: 'getAbsenceScheduleReport',
        method: 'get',
        path: '/vacations/absence/schedule/report',
    },
    {
        name: 'getMyDepartmentSchedule',
        method: 'get',
        path: '/vacations/plan/my/schedule',
    },
    {
        name: 'getMyDepartmentScheduleReport',
        method: 'get',
        path: '/vacations/plan/my/schedule/report',
    },

    //--------------------------------------------------

    {
        name: 'getPlanBalances',
        method: 'get',
        path: '/vacations/plan/balances',
    },
    // {
    //     name: 'getPlanVacationIntersections',
    //     method: 'get',
    //     path: '/vacations/plans/:plan_id/vacations/:vacation_id/intersections',
    // },
    // {
    //     name: 'getScheduleReport',
    //     method: 'get',
    //     path: '/vacations/plan/schedule/report',
    // },
    // {
    //     name: 'getPlanT7Report',
    //     method: 'get',
    //     path: '/vacations/plan/t7-report',
    // },
    {
        name: 'getBalanceTransactions',
        method: 'get',
        path: '/balances/transactions',
    },
    {
        name: 'addBalanceTransaction',
        method: 'post',
        path: '/balances/transactions',
    },
    {
        name: 'getBalanceTransactionTypes',
        method: 'get',
        path: '/balances/transaction-types',
    },
    {
        name: 'rollbackBalanceTransaction',
        method: 'post',
        path: '/balances/transactions/:balance_transaction_id/rollback',
    },
    {
        name: 'getMyBlockingDates',
        method: 'get',
        path: '/vacations/my/blocking-dates',
    },
    {
        name: 'getHRBlockingDates',
        method: 'get',
        path: '/vacations/hr/blocking-dates',
    },
];

export default endpoints;
