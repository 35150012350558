const filterObject = (obj, filter, filterValue) => Object.keys(obj).reduce(
    (acc, val) => (obj[val][filter] === filterValue
        ? {
            ...acc,
            [val]: obj[val],
        }
        : acc),
    {},
);
export default filterObject;
