<template>
    <div class="main__header">
        <div class="main__title title" slot="main-title">{{ title }}</div>

        <slot name="mainNotification" />

        <div class="main__actions">
            <template v-if="hasTabs && (Object.keys(tabs).length !== 1)">
                <ul class="tabs-heading show-tablet">
                    <li
                        v-for="(tab, index) in tabs"
                        :key="index"
                        @click="selectTab(tab.name)"
                        :class="['tab-heading content__caption', { active: tab.name === selectedTab }]"
                    >
                        {{ tab.heading }} <template v-if="tab.statistics >= 0">({{ tab.statistics }})</template>
                    </li>
                </ul>

                <div class="tabs-heading--select hide-tablet">
                    <span class="tabs-heading__icon" />
                    <select
                        @change="selectTab($event.target.value)"
                    >
                        <option
                            v-for="(tab, index) in tabs"
                            :key="index"
                            :value="tab.name"
                        >
                            {{ tab.heading }} <template v-if="tab.statistics >= 0">({{ tab.statistics }})</template>
                        </option>
                    </select>
                </div>
            </template>

            <slot name="headerContent" v-else />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            hasTabs: {
                type: Boolean,
                default: false,
            },
            tabs: {
                type: Object,
                default: () => {},
            },
            initialTab: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                title: this.$t(this.$route.meta.title),
                selectedTab: this.initialTab,
            };
        },
        computed: {
            pageProperties() {
                return this.$store.state.pageProperties;
            },
        },
        watch: {
            initialTab(newVal) {
                this.selectedTab = newVal;
            },
            'pageProperties.title'() {
                this.title = this.pageProperties.title || this.title;
            },
        },
        methods: {
            selectTab(name) {
                this.$emit('select-tab', name);
            },
        },
    };
</script>
