export default {
    vacations: 'Vacations',
    my_vacations: 'My vacations',
    subordinates_vacations: 'Subordinates vacations',
    hr_administration: 'HR-administration',
    use_vacations: 'Use vacations',
    vacation_schedule: 'Vacation schedule',
    department_schedule: 'Department schedule',
    absence_schedule: 'Absence schedule',
    complete_planning_title: 'Congratulations!',
    complete_planning_text: 'You have successfully completed the plan',
    renew_planning_text: 'You have resumed planning your vacation.',

    requests_found: 'Requests was found',
    vacation_requests: 'Vacation requests',
    planned_holidays: 'Planned holidays',
    planned_holiday: 'Vacation plan',
    my_planned_holiday: 'My vacation plan',
    exception: 'Overlapping',
    has_exceptions_yes: 'Yes',
    has_exceptions_no: 'No',
    vacation_type: 'Type of leave',
    absence_type: 'Type of absence',
    planned_vacation: 'Planned',
    planned_vacation_short: 'Planned',
    unplanned_vacation_short: 'Unplanned',
    status_request: 'Status',
    status: 'Status',
    comment: 'Comment',
    view_history_changes: 'View the history of changes',
    form_application: 'Form application',
    send_approval: 'Send for approval',
    create_and_approve: 'Create and approve',
    modal_title: 'Leave request',
    employee_information: 'About the employee',
    information: 'Vacation information',
    employee: 'Employee',
    position: 'Position',
    available: 'Available',
    available_from: 'Available from {date}',
    scheduled: 'Scheduled',
    planned_days: 'Planned days',
    unplanned_days: 'Unplanned days',
    scheduled_on: 'Planned for {year}',
//    agreed: 'out of them approved',
    all: 'All',
    transferred_from_previous_periods: 'carried over from previous periods',
    date: 'Date',
    type_absence: 'The type of absence',
    department: 'Department',
    select_employee: 'Select the employee',
    select_department: 'Select the department',
    confirmation_cancellation: 'Confirm the request cancellation',
    history: 'History',
    plan_history: 'Plan history',
    amount_vacations: 'Amount of vacations',
    paid: 'Paid',
    unpaid: 'Unpaid',
    view_schedule: 'View vacation schedule',
    schedule: 'Schedule',
    duration: 'Days',
    plan_type: 'Plan type',
    full_name: 'Fullname',
    updated_by: 'Changed by',
    updated_at: 'Changed at',

    approve_selected_vacations: 'Approve the selected vacations',

    errors: {
        title: 'Error!',
        dates_required: 'The start and end dates of the vacation are mandatory',
        first_application: 'The application, created first, must be of a duration of 14 days or more',
        holidays_cannot_start: 'Vacation cannot start with a holiday or a weekend',
        days_unavailable: 'The vacation cannot be longer than the number of remaining vacation days',
        shorter_min_count_days: 'The vacation is shorter than the minimum number of days',
        longer_max_count_days: 'he vacation is longer than the maximum number of days',
        application_period: 'The beginning and end dates of the application must necessarily fit into the period from January 1 to December 31 of the following year',
        start_vacation: 'The application start date must not be earlier than 2 weeks from the current date',
        required_vacation_type: 'Select vacation type',
        required_documents_for_study_paid_vacation: 'It is necessary to attach to the application a copy of the certificate from the educational institution and the application for study leave signed by the supervisor',
        recall_date_required: 'Select recall date',
        comment_required: 'Enter the comment',
        recall_comment_required: 'Enter reason for recall',
        sum_duration_new_vacations_equal_duration_old_vacation: 'The amount of transferred days must be equal with the original request. Use buttons "Add period" or "Delete".',
        cannot_create_request_only_weekend: 'For this type of vacation, you cannot create a request only on a weekend',
        recall_id_not_isset: 'There is no vacation review for this application',
        vacation_id_not_isset: 'Not found application number',
        transfer_id_not_isset: 'There is no vacation transfer for this application',
        no_vacation_selected: 'No vacation selected',
        no_plan_selected: 'No plan selected',
        no_mass_action_type_selected: 'No mass action selected',
    },

    plan_vacation: 'Plan vacation',
    new_request: 'New request',

    // Declination
    declination_days: ['day', 'days', 'days'],

    // Filter
    search_option: 'Search option',
    search_option_filter: {
        request_number: '№ request',
        full_name: 'Employee',
        employee: 'Employee',
        position: 'Position',
        head: 'Manager',
        all_users: 'All employees',
        users_type: 'Type of employee',
        planned_vacation: 'Planned vacation',
        unplanned_vacation: 'Unplanned vacation',
        absence_type: 'Type of absence',
        vacation_type: 'Type of vacation',
        has_exceptions: 'Exceptions',
        has_intersections: 'Overlapping',
        editing: 'Editing',
        coordination: 'Waiting for approval',
        planning: 'Planning',
        planned: 'Planned',
        agreed: 'Approved',
        order_issued: 'Order issued',
        progress: 'On vacation',
        completed: 'Completed',
        canceled: 'Canceled',
        interrupted: 'Corrected',
        required_reaction: 'Requires reaction',
        uncompleted: 'Uncompleted',
        transfer: 'Re-planning',
        order_number: '# order',
        order_number_1c: '# order 1C',
//        schedule: {
//            all: 'All',
//            not: 'Not specified',
//        },
//        only_my_vacations: 'Only my vacations',
    },
    empty_schedule_filter: 'No result found for the selected filter',

    // Plan vacations
    plan_title: 'Plan',
    plan: 'Vacation plan',
    planning: 'Plan balance',
    plan_for_year: 'Vacation plan {year}',
    plan_for_year_modal: 'Vacation plan for {year}',
    my_plan: 'My plan',
    plans_for_subordinates: 'Plans of subordinates',
    plan_status: 'Plan status',
    complete_planning: 'Send for approval',
    renew_planning: 'Renew planning',
    planned_requests: 'Planned requests',

    planned_requests_approve: 'Approve selected vacation plans',
    planned_all_requests_approve: 'Approve all vacation plans',

    warning_want_to_recoordinate: 'Attention: The second approval of the manager will be required.',
    planning_period_warning: 'Please note: vacation planning for the year {year} is open from {start_date} till {end_date}',
    planning_period_completed: 'Planning period completed',
    confirm_plan_confirm: 'Approve vacation plan',
    confirm_plan_cancel: 'Confirm rejection',
    confirm_plan_cancel_comment_note: '* You can provide the ID of vacation request you propose to be changed',
    cancel_plan_comment: 'Please provide comment (mandatory)',
    state_draft: 'Draft',
    changes: 'Actions and events',
    author: 'Author',

    confirm_reject: 'Confirm rejection',
    confirm_cancel: 'Confirm cancel',
    cancel_comment: 'Please provide comment (mandatory)',

    export_requests: 'Export requests',
    export_schedule: 'Export schedule',
    intersections: 'Overlapping',
    agreed: 'Approved',
    on_planning: 'Planning',
    not_agreed: 'Not approved',
    coordination: 'Waiting for approval',
    change_history: 'Change history',
    amount_days: 'Days',
    editing_vacation: 'Editing vacation request',
    add_absence: 'Add absence',
    all_years: 'All years',
    all_statuses: 'All statuses',
    no_vacation_planned_yet: 'No vacation planned yet',
    too_short_comment: 'Minimum length of comment is 5 characters',

    hint: {
        title: 'Plan statuses',
        status_draft: {
            name: 'Draft',
            hint: 'the initial status of the plan, in this status employee can make any changes, add and delete requests to his/her vacation plan.',
        },
        status_coordination: {
            name: 'Waiting for approval',
            hint: 'employee has sent his/her annual plan to the manager for approval. In this status manager can approve or reject the plan and send it for revision (return it to the "Draft" status). Employee still can make changes before the process deadline set for employees.',
        },
        status_agree: {
            name: 'Approved',
            hint: 'the status of the plan, when the manager has approved the employee\'s plan. Employee still can make changes before the process deadline set for employees. In this case the plan will be returned for the second approval ("Waiting for approval" status).',
        },
        status_awaiting_completion: {
            name: 'Approved, waiting for the end of planning process',
            hint: 'the status in which employee\'s planning deadline is over and manager has approved the plan. Manager can still make changes – reject and send the plan to employee for revision, in this case the plan will get the "Under correction" status. After the manager\'s deadline the plan will be turned to the "Completed" status.',
        },
        status_requires_manager_action: {
            name: 'Waiting for approval, employee can\'t make changes',
            hint: 'the status in which employee\'s planning deadline is over. If employee has not completed his/her plan, the system creates missing vacation requests automatically and sends the whole plan for the manager\'s approval. Manager can approve or reject the plan and send it to employee for revision, in this case the plan will be moved to "Under correction" status.',
        },
        status_adjustment: {
            name: 'Under correction',
            hint: 'the plan was rejected by manager after employee\'s planning deadline. Employee should make changes and send the corrected plan for approval again.',
        },
        status_completed: {
            name: 'Completed',
            hint: 'this is the planning process closure. All vacation plans, regardless of their status at this moment, including plans with "Waiting for approval" status, are turned to the "Completed" status. All vacation plans are considered as approved and are taken for legal document\'s preparation and signing.',
        },
        status_replanned: {
            name: 'Re-planned from previous year',
            hint: '',
        },
    },

    confirm_plan: 'Approve the plan',
    select_amount_plans: '{amount} plans selected',
    select_all_plans: 'Select all plans',
    select_amount_requests: '{amount} leaves selected',
    select_all_requests: 'Select all leaves',
    request_number: 'ID',
    plan_number: 'ID',
    period: 'Period',
    head: 'Manager',

    confirm_plan_text: 'Approve on plan #{plan_id} for {year} for {username}',
    complete_plan_text: 'Send for approval plan #{plan_id} for {username} for {year}',
    complete_plan_complete: 'Send plan for approval',
    all_absences: 'All absences',

    reject_plan: 'Reject plan #{plan_id}',

    plan_balance: {
        brought_forward_in: 'Brought forward {year}',
        entitled_in: 'Entitled in {year}',
        available_for_planning_in: 'Available for planning {year}',
        planned_in: 'Planned in {year}',
        remains_planned_in: 'Remains to be planned for 2024',
    },
    current_balance: {
        brought_forward_in: 'Brought forward from {year}',
        entitled_in: 'Entitled in {year}',
        planned_in: 'Planned in {year}',
        available: 'Available',
        used_in: 'Used in {year}',
    },
    export_t7: 'Export to T7',
    recall: 'Recall',
    cancel_recall: 'Cancel recall',
    cancel_recall_from_vacation: 'Cancel recall from vacation №{request_number}',
    recall_from_vacation: 'Recall from vacation',
    recall_from: 'Recall from',
    recall_date: 'Recall date',
    recall_reason: 'Reason for recall from vacation',
    confirm_recall: 'Confirm recall from vacation',
    reject_recall: 'Reject recall from vacation',
    agree_recall: 'Agree',
    refuse_recall: 'Refuse',
    interrupted_from: 'from {date}',
    download_vacation_recall_statement: 'Download vacation recall statement',
    download_vacation_recall_order: 'Download vacation recall order',
    export_absence: 'Export',
    transfer: 'Re-plan',
    transfer_to_year: 'Re-plan for {year}',
    transferred_from_year: 'Re-planned from {year}',
    transfer_vacation_request: 'Re-plan vacation request №{request_number}',
    transfer_vacation_request_to_next_year: 'Re-plan vacation request №{request_number} for {year}',
    transfer_vacation_request_to_next_year_attention: 'Please pay attention to the planning rules taking into account the re-planning of unused vacation days:<br><br>1. Planned vacations of the current year can be re-planned to the next calendar year, if the following conditions are met:<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. there is at least one used or planned vacation in the current year 14 days continuously;<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. the total duration of vacation days used in the current year is at least <strong>{minimum_limit_days}</strong> calendar days;<br><br>2. The vacations moved to the next year cannot be divided into parts, and they should be used by the end of the next year.<br><br>',
    to_transfer_vacation_request: 'Re-plan vacation request',
    new_periods: 'New periods',
    add_period: 'Add period',
    remaining_days: 'Remaining days',
    sum_duration_new_vacations_equal_duration_old_vacation: 'The amount of transferred days must be equal with the original request',
    download_vacation_transfer_statement: 'Download vacation re-planning statement',
    download_vacation_transfer_order: 'Download vacation re-planning order',
    cancel_transfer: 'Cancel re-planning',
    cancel_transfer_request: 'Cancel vacation re-planning',
    confirm_transfer: 'Confirm re-planning',
    reject_transfer: 'Reject re-planning',
    download_vacation_statement: 'Download statement',
    download_vacation_order: 'Download order',
    order_number: '# order',
    order_number_1c: '# order 1C',
    transfer_request: {
        confirmed: 'Re-planning is confirmed',
        rejected: 'Re-planning is rejected',
        failed: 'Re-planning is failed',
        not_found: 'Re-planning is not found',
    },
    recall_request: {
        confirmed: 'Recall is confirmed',
        rejected: 'Recall is rejected',
        failed: 'Recall is failed',
        not_found: 'Recall is not found',
    },
    success_export_report: 'The report will be generated and sent to your e-mail',

    balances: {
        balance: 'Balance',
        balances: 'Balances',
        changes: 'Changes',
        add: 'Add',
        add_balance: 'Add balance',
        write_off: 'Write off',
        write_off_balance: 'Write off balance',
        employee: 'Employee',
        year: 'Year',
        days: 'Days',
        balance_type: 'balance tyoe',
        updated_by: 'Changed by',
        updated_at: 'Changed at',
        comment: 'Comment',
        rollback_balance_text: 'Cancel accrual of balance "{balance_type} {days}" for employee {employee}',
        success_rollback_balance_text: 'Balance accrual successfully canceled',
        available_days: 'Available days',
        success_add_balance_text: 'Balance has been successfully added',
        success_write_off_balance_text: 'Balance has been successfully debited',

        errors: {
            title: 'Error',
            employee_required: 'Select the employee',
            balance_type_required: 'Select the balance type',
            year_required: 'Select year',
            days_required: 'Enter days',
            days_required_max: 'The value in the Days field cannot be more than 100',
            comment_required: 'Enter comment',
        },
    },

    vacation_statuses: {
        coordination: 'Waiting for approval',
        editing: 'Editing',
        planning: 'Planning',
        planned: 'Planned',
        agreed: 'Approved',
        order_formed: 'Order issued',
        progress: 'On vacation',
        interrupted: 'Corrected',
        completed: 'Completed',
        canceled: 'Canceled',
        transfer: 'Re-planning',
    },

    vacation_types: {
        paid: 'Paid vacation',
        unpaid: 'Unpaid leave',
        study_paid: 'Paid study leave',
        study_unpaid: 'Unpaid study leave',
        blood_donation: 'Blood donation',
        radiation: 'Extra vacation for staying in area of high radiation',
    },

    vacation_recall_statuses: {
        new: 'New',
        accepted: 'Accepted',
        rejected: 'Rejected',
        failed: 'Failed',
        cancelled: 'Cancelled',
    },

    vacation_transfer_statuses: {
        new: 'New',
        accepted: 'Accepted',
        rejected: 'Rejected',
        failed: 'Failed',
    },

    vacation_plan_statuses: {
        draft: 'Draft',
        coordination: 'Coordination',
        agreed: 'Agreed',
        awaiting_completion: 'Awaiting completion of planning period',
        requires_manager_action: 'Requires action by the manager',
        adjustment: 'Adjustment',
        completed: 'Completed',
    },

    balance_transaction_types: {
        basic: 'Basic leave',
        far_north: 'Work in the Far North',
        irregular: 'Irregular working hours',
        hazard: 'Harmful or hazardous working conditions',
        other: 'Other',
    },
};
