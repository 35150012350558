<template>
    <div class="login">
        <div class="login__header">
            <div class="logo" />
        </div>

        <form class="form form__login" @submit.prevent="recover()">
            <div class="login__title title">{{ $t('recover_password.title') }}</div>
            <div class="login__text text text--left">{{ $t('recover_password.text') }}</div>

            <div class="form__row form__row--indent-login">
                <label for class="form__label label login__label">
                    <span class="label__text">{{ $t('email') }}</span>
                    <input
                        type="email"
                        class="form__input form__input login__input"
                        :placeholder="$t('enter_email')"
                        v-model="oForm.email"
                    />
                </label>
            </div>

            <div class="form__row form__row--indent-login">
                <button
                    class="btn btn--accent btn--login"
                    :class="{'disabled': isRecoverButtonLoading}"
                    :disabled="isRecoverButtonLoading"
                >
                    <template v-if="isRecoverButtonLoading">
                        <span class="btn--loading" />
                    </template>

                    {{ $t('recover_password.btn') }}
                </button>
            </div>

            <div class="login__info info">
                <div class="info__label info__label--login">
                    <a href="mailto:support@fmf.ru" class="info__label login__link">{{ $t('login_contact_with_support') }}</a>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                oForm: {
                    email: '',
                },
                isRecoverButtonLoading: false,
            };
        },
        methods: {
            recover() {
                const oRequestParams = {
                    requestBody: {
                        email: this.oForm.email,
                    },
                };

                this.isRecoverButtonLoading = true;
                this.$Api.call('base.recover', oRequestParams)
                    .then(() => {
                        this.$notifySuccess({
                            title: this.$t('recover_password.success.title'),
                            text: this.$t('recover_password.success.text'),
                        });

                        this.oForm.email = '';
                        this.isRecoverButtonLoading = false;
                    })
                    .catch(() => {
                        this.isRecoverButtonLoading = false;
                    });
            },
        },
    };
</script>
