const Help = () => import('./views/help/index');
const HelpInfo = () => import('./views/help/info/index');

const routes = [
    {
        path: '/help',
        name: 'help.help',
        components: {
            default: Help,
        },
        meta: {
            title: 'help.help',
            middlewareAuth: true,
            showModal: false,
        },
    },
    {
        path: '/help/info',
        name: 'help.info_name',
        components: {
            default: HelpInfo,
        },
        meta: {
            title: 'help.info_name',
            middlewareAuth: true,
            showModal: false,
        },
    },
];

export default routes;
