export default {
    settings: 'Help Center',
    info_name: 'Help Center',
    notifications_name: 'Notification settings',
    setting_name: 'Name',
    condition: 'Condition',
    type: 'Type',

    menu: {
        help_center: 'Help center',
    },

    help_instructions: 'Instructions',
    help_using: 'Using Vacation Solution',
    help_profile_info: 'Your profile',
    help_support_service: 'Technical support service',
    help_subtitle_planning: 'Vacation planning',
    help_subtitle_use: 'Use vacations',
    privacy_and_security: 'Confidentiality and security',
    privacy_agreement: 'Confidentiality agreement',
    privacy_info: 'Copyright © 2019-2022 ООО “ЦЕНТР ИНФОРМАЦИОННЫХ ТЕХНОЛОГИЙ "ФМФ”',

    how_access_profile: 'How do I sign up for my personal profile?',
    how_access_profile_text: 'After logging in to VS with your login and password you will need to:',
    how_access_profile_list_1: 'Place the cursor on your name in the upper right of the app',
    how_access_profile_list_2: 'In the drop-down menu you should press the "My profile" section. This page contains your personal information',

    vacation_solution_instructions: 'Instructions for working with Vacation Solution',
    vacation_solution_training_materials: 'Training materials on Vacation Solution',
    instructions_employees: 'Vacation planning manual for employees (RU)',
    instructions_head_rus: 'Vacation planning manual for managers (RU)',
    instructions_head_en: 'Vacation planning manual for managers (EN)',
    instructions_hr: 'User\'s manual for HR (RU)',
    instructions_administrator_working_hours: 'User\'s manual for the Time Manager (RU)',

    instructions_use_head_ru: 'Use vacation - Manual for employees (RU)',
    instructions_use_employee_ru: 'Use and control vacation - Manual for managers (RU)',
    instructions_use_employee_en: 'Use and control vacation - Manual for managers (EN)',

    vacation_solution_video_instructions: 'Instructions for working with Vacation Solution (RU)',
    video_instructions_employees: 'Video-guide for employees (RU)',
    video_instructions_head_rus: 'Video-guide for managers (RU)',
    video_instructions_head_en: 'Video-guide for managers (EN)',
    video_instructions_hr: 'Video tutorial for HR (RU)',

    update_profile: 'How do I update my Vacation Solution profile information (e.g., name, username, or email address)?',
    update_profile_text: 'To update your profile information, including all the contents of the "General Information" section, as well as the e-mail address associated with your account, you need to contact your company\'s HR department.',
    update_profile_contacts: 'How can I change the "Contact details"?',
    update_profile_contacts_list_1: 'Open the "My Profile" section;',
    update_profile_contacts_list_2: 'Enter the information in the "Phone" field and press',

    update_profile_photo: 'How can I add or edit a profile photo?',
    update_profile_photo_text: 'To add or edit your profile photo:',
    update_profile_photo_list_1: 'Open the "My Profile" section;',
    update_profile_photo_list_2: 'Point your cursor at a photo of your profile;',
    update_profile_photo_list_3: 'Click "Upload photo";',
    update_profile_photo_list_4: 'Select the location from which you want to import photos;',
    update_profile_photo_list_5: 'Press "Open".',

    how_get_out_profile: 'How can I log out from Vacation Solution?',
    how_get_out_profile_text: 'To log out from the profile:',
    how_get_out_profile_list_1: 'Point to your profile icon;',
    how_get_out_profile_list_2: 'Click "Exit" in the drop-down list.',

    support_service_text: 'Any questions can be asked via the feedback form to the FMF Technical Support Team. Our support staff is ready to help you every day from 9:00 a.m. to 6:00 p.m. Moscow time. We recommend you to check the answers in the section of frequently asked questions.',
    support_service_text_link: '"Information Center"',
    support_service_form_title: 'Feedback form:',
    support_service_form_name: 'Message from the name of',
    support_service_form_name_label: 'Enter your full name',
    support_service_form_data_label: 'Enter the text',
    support_service_form_appeal: 'Category of reference',
    support_service_form_priority: 'Priority',
    support_service_form_priority_label: 'Type of priority',
    support_service_form_theme: 'Subject of reference',
    support_service_form_theme_label: 'Enter the subject of the message',
    support_service_form_textarea: 'Message text',
    support_service_form_btn: 'Send a message',
    support_service_category_plan_vacations: 'Vacation planning {year}',
    support_service_category_use_vacations: 'Use vacations',
    support_service_priority_high: 'High',
    support_service_priority_medium: 'Medium',
    support_service_priority_low: 'Low',
    errors_title: 'Error!',
    support_service_form_errors: {
        required_category: 'Select category of reference',
        required_priority: 'Select priority',
        required_subject: 'Enter the subject of the message',
        required_message: 'Enter the text of the message',
    },
    support_service_form_success_message: 'Your question has been successfully sent!',
    reference: 'Reference',
    statuses: {
        paid_vacation_title: 'Paid vacation',
        paid_vacation_text: 'You can apply for paid vacation if there is an available vacation balance for the current calendar year. Your application will be sent to the manager for approval.',
        vacation_without_saving_title: 'Unpaid leave',
        vacation_without_saving_text: 'You can apply for unpaid leave on the required number of days. The application will not affect your paid vacation balance. Your application will be sent to the manager for approval.',
        study_paid_vacation_title: 'Paid study leave',
        study_paid_vacation_text: 'You need to provide "Reference issued by an academy" and "Application" in the attachment. The application will not affect your vacation balance. Your application will be sent for approval to your manager and HR specialist.',
        study_unpaid_vacation_title: 'Unpaid study leave',
        study_unpaid_vacation_text: 'You need to provide "Reference issued by an academy" and "Application" in the attachment. The application will not affect your vacation balance. Your application will be sent for approval to your manager and HR specialist.',
        pvtr_days_wedding_title: 'Staff hand book: Wedding days',
        pvtr_days_wedding_text: 'You can apply for a paid leave in case of your wedding for a period of up to 3 working days (for example, from Friday to Tuesday or from Thursday to Monday, public holidays are not included). The application will not affect your vacation balance. Your application will be sent to the manager for approval.',
        pvtr_days_funeral_title: 'Staff hand book: Funeral days',
        pvtr_days_funeral_text: 'You can apply for a paid leave in case of the funeral of a close relative (spouses, children, parents, brothers, sisters) for a period of up to 3 working days (for example, from Friday to Tuesday or from Thursday to Monday, public holidays are not included). The application will not affect your vacation balance. Your application will be sent to the manager for approval.',
        pvtr_days_birth_title: 'Staff hand book days: Birth of child',
        pvtr_days_birth_text: 'You can apply for a paid leave in case of the birth of a child for 1 working day. The application will not affect your vacation balance. Your application will be sent to the manager for approval.',
        pvtr_days_relocation_title: 'Staff hand book days: Relocation',
        pvtr_days_relocation_text: 'You can apply for a paid leave in case of the move for 1 working day. The application will not affect your vacation balance. Your application will be sent to the manager for approval.',
        blood_donation_title: 'Blood donation',
        blood_donation_text: 'You need to provide the blood donation confirming document in the attachment. You can apply up to 2 calendar days (at the same time or separately). The application will not affect your vacation balance. Your application will be sent to the manager for approval.',
        vacation_disabled_child_title: 'Extra paid weekend to care for a disabled child',
        vacation_disabled_child_text: 'You need to provide "Second parent certificate" and "Application" in the attachment. You can apply for a period of up to 4 working days per month without transfer to the next month. The application will not affect your vacation balance. Your application will be sent for approval to your manager and HR specialist.',
        vacation_high_radiation_zone_title: 'Extra vacation for staying in area of high radiation',
        vacation_high_radiation_zone_text: 'Victims of radiation exposure due to the accident at the Chernobyl nuclear power plant are entitled to additional leave. Those who lived in the zone of radioactive contamination in the period from 26.04.1986 can take leave for 14 calendar days, and from 02.12.1995 - for 7 calendar days.',
    },
};
