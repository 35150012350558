import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

//import en from '@/lang/en';
import ru from '@/lang/ru';

//let i18nRu = [];
//i18nRu['base'] = ru;

//let i18nEn = [];
//i18nEn['base'] = en;

const messages = {
    //en: en,
    ru: ru,
//    'en': i18nEn,
//    'ru': i18nRu,
};

const i18n = new VueI18n({
    //locale: window.localStorage.getItem('locale') || 'en',
    locale: 'ru',
    fallbackLocale: 'ru',
    messages,
});

export default i18n;
