export default {
    settings: 'Настройки',
    common_name: 'Настройки общие',
    vacations_name: 'Настройки отпусков',
    notifications_name: 'Настройки уведомлений',
    setting_name: 'Название',
    condition: 'Условие',
    type: 'Тип',
    required_start_info: '- поля обязательные к заполнению',
    save: 'Сохранить',

    vacations: {
        vacation_planning: 'Параметры плана',
        vacation_planning_period: 'Период проведения планирования отпусков',
        days_before_start_vacation_planning_for_send_notification: 'Количество дней до начала периода планирования отпусков для отправки уведомления',
        minimum_amount_vacation_days: 'Минимальное количество дней в отпуске',
        maximum_amount_vacation_days: 'Максимальное количество дней в отпуске',
        days_before_send_requests_for_payment: 'Количество дней, за которое отправляются заявки на оплату, согласованные в системе',
        for_exceptions: '(для исключений)',
        vacations_planning_period_start_date: 'Дата начала планирования',
        vacations_planning_period_employee_end_date: 'Дата окончания планирования для подчиненных',
        vacations_planning_period_end_date: 'Дата окончания планирования для руководителей',
        vacations_planning_period_planning_year: 'Год планирования',
        errors: {
            title: 'Ошибка!',
            vacations_planning_period_planning_year_required: 'Введите год планирования',
            employee_end_date_after_start_date: '"Дата окончания планирования для подчиненных" должна быть больше, чем "Дата начала планирования"',
            end_date_after_start_date: '"Дата окончания планирования для руководителей" должна быть больше, чем "Дата окончания планирования для подчиненных"',
            next_year_transfer_available_after_date_required: 'Введите дату открытия возможности переноса отпусков на следующий год',
            next_year_transfer_minimum_limit_of_current_year_days_off_required: 'Введите минимальный лимит отгулянных дней в текущем году',
        },

        start_planning: 'Начало планирования',
        confirm_start_planning: 'Вы уверены, что хотите объявить начало периода планирования?',
        success_start_planning: 'Период планирования успешно объявлен',

        confirm_start_notification_planning_period: 'Вы уверены, что хотите запустить рассылку уведомлений о начале планирования?',
        success_start_notification_planning_period: 'Рассылка уведомлений о начале планирования успешно запущена',

        end_planning_for_employees: 'Завершение планирования для сотрудников',
        confirm_end_planning_for_employees: 'Вы уверены, что хотите завершить период планирования для сотрудников?',
        success_end_planning_for_employees: 'Период планирования для сотрудников успешно завершен',

        confirm_start_notification_employee_planning_period_complete: 'Вы уверены, что хотите запустить рассылку уведомлений о завершении планирования для сотрудников?',
        success_start_notification_employee_planning_period_complete: 'Рассылка уведомлений о завершении планирования для сотрудников успешно запущена',

        end_planning: 'Завершение планирования для руководителей',
        confirm_end_planning: 'Вы уверены, что хотите завершить период планирования?',
        success_end_planning: 'Период планирования успешно завершен',

        auto_planning: 'Автопланирование',
        auto_planning_note: 'В автоматическом режиме запускается вместе с шагом 3',
        confirm_start_auto_planning: 'Вы уверены, что хотите запустить автопланирование?',
        success_start_auto_planning: 'Автопланирование успешно запущено',

        planning_period_completed: 'Планирование завершено',

        settings_transfer_vacation_request_to_next_year: 'Параметры переноса',
        start_date_transfer_vacation_request_to_next_year: 'Дата открытия возможности переноса отпусков на следующий год',
        minimal_limit_holidays_in_current_year: 'Минимальный лимит отгулянных дней в текущем году',

        button_start: 'Запустить',
        button_start_send_notifications: 'Запустить рассылку уведомлений',
        success_save: 'Настройки успешно сохранены',

        change_history: 'История изменений',
        history: 'История',
        author: 'Автор',
        date: 'Дата',
        event: 'Событие',
        status: 'Статус',

        edit_mail_template_modal_title: 'Редактирование шаблона уведомления',
        view_mail_template_modal_title: 'Просмотр шаблона уведомления',
        mail_template: {
            number: 'Номер шаблона',
            name: 'Название шаблона',
            send_period: 'Периодичность отправки',
            legend: 'Условные обозначения',
            updated_by: 'Изменено',
            updated_at: 'Дата изменения',

            name_ru: 'Название шаблона (RU)',
            name_en: 'Название шаблона (EN)',
            // sms_text: 'Текст смс уведомления',
            viewable: 'Отображать в настройках уведомлений пользователя',
            switchable: 'Разрешено редактировать в настройках уведомлений пользователя',
            subject: 'Тема письма',
            subject_ru: 'Тема письма (RU)',
            body_ru: 'Текст письма (RU)',
            body: 'Текст письма',
            subject_en: 'Тема письма (EN)',
            body_en: 'Текст письма (EN)',

            errors: {
                title: 'Ошибка!',
                name_ru_required: 'Введите название шаблона (RU)',
                name_en_required: 'Введите название шаблона (EN)',
                subject_ru_required: 'Введите тему письма (RU)',
                body_ru_required: 'Введите текст письма (RU)',
                subject_en_required: 'Введите тему письма (EN)',
                body_en_required: 'Введите текст письма (EN)',
            },
        },
    },

    common: {
        common_info: 'Общие данные',
        communication: 'Данные для связи',
        logo: 'Логотип компании',
        upload_logo: 'Загрузить лого',
        ceo_full_name: 'ФИО генерального директора',
        legal_entity_full_name: 'Полное наименование юридического лица',
        legal_entity_short_name: 'Краткое название юридического лица (если есть)',
        legal_address: 'Юридический адрес компании',
        chief_accountant_full_name: 'ФИО главного бухгалтера',
        hr_manager_full_name: 'ФИО руководителя отдела кадров',
        company_phone: 'Телефон компании',
        company_email: 'E-mail компании',
        notifications_email: 'E-mail для рассылки автоматических уведомлений',
        notify_success: 'Настройки успешно обновлены',
        notify_success_logo: 'Логотип успешно обновлен',
        errors: {
            empty_file_logo: 'Выберите файл',
            not_need_type_logo: 'Выберите файл в формате png, jpg, jpeg или svg',
        },
    },

    menu: {
        common: 'Общие',
        vacations: 'Отпуска',
        notifications: 'Уведомления',
        users: 'Физ. лица',
    },

    search_option: 'Параметры поиска',
    search_option_filter: {
        employee: 'ФИО сотрудника',
        enter_employee: 'Введите ФИО сотрудника',
        manager: 'Менеджер',
        select_manager: 'Выберите менеджера',
        position: 'Должность',
        enter_position: 'Введите должность',
    },
};
