export default function getEnv(name) {
    if (
        typeof window.configs !== 'undefined'
        && typeof window.configs[name] !== 'undefined'
    ) {
        return window.configs[name];
    }

    if (typeof process.env[name] !== 'undefined') {
        return process.env[name];
    }

    return undefined;
}
