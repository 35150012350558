<template>
    <Main>
        <template v-slot:contentBody>
            <div class="content__block">
                <div class="form form__set">
                    <div class="form__group form__row form__row--start form__row--wrap form__row--margin">
                        <label class="form__label label input-file__wrapper">
                            <div class="form__input input-file input">
                                <input
                                    type="file"
                                    id="file-input"
                                    ref="unitsFile"
                                    class="visually-hidden"
                                    accept=".xlsx, .xls, .csv"
                                    @change="previewFile"
                                />

                                <label
                                    for="file-input"
                                    class="btn btn--common input-file__btn"
                                    @change="previewFile"
                                >
                                    {{ $t('choose_file') }}
                                </label>

                                <span id="file-chosen" class="input-file__chosen">{{ fileName }}</span>

                                <span
                                    v-if="isChosen"
                                    class="input-file__delete"
                                    @click.prevent="deleteFile"
                                />
                            </div>
                        </label>
                    </div>

                    <button
                        type="button"
                        class="btn btn--accent"
                        @click="sendForm"
                        :class="{'disabled': isImportButtonDisabled}"
                        :disabled="isImportButtonDisabled"
                    >
                        <template v-if="isImportButtonDisabled">
                            <span class="btn--loading" />
                        </template>

                        {{ isImportButtonDisabled ? $t('importing') : $t('import') }}
                    </button>
                </div>
            </div>
        </template>
    </Main>
</template>

<script>
    import Main from '@/components/main/Main';

    export default {
        components: {
            Main,
        },
        data() {
            return {
                validTypes: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'],
                isChosen: false,
                file: null,
                defaultFileName: this.$i18n.t('structure.import.no_file_chosen'),
                fileName: '',
                isImportButtonDisabled: false,
            };
        },
        created() {
            this.setDefaultFileName();
        },

        methods: {
            setDefaultFileName() {
                this.fileName = this.defaultFileName;
            },

            previewFile() {
                if (this.$refs.unitsFile.files.length === 0) {
                    return;
                }

                this.file = this.$refs.unitsFile.files[0];
                this.fileName = this.file.name;
                this.isChosen = true;
            },

            deleteFile() {
                this.$refs.unitsFile.value = '';
                this.isChosen = false;
                this.setDefaultFileName();
            },

            validateBeforeImportUnits() {
                const errors = [];

                if (!this.file || this.file.length === 0) {
                    errors.push(this.$t('structure.import.empty_file'));
                }

                if (this.file !== null && !this.validTypes.includes(this.file.type)) {
                    errors.push(this.$t('structure.import.not_need_type'));
                }

                if (errors.length) {
                    this.$notifyError({
                        title: this.$t('structure.errors.title'),
                        text: errors,
                    });

                    return false;
                }

                return true;
            },
            importUnits() {
                if (!this.validateBeforeImportUnits()) {
                    return;
                }

                const oFormData = new FormData();
                oFormData.append('file', this.file);

                const oRequestParams = {
                    requestBody: oFormData,
                };

                this.isImportButtonDisabled = true;
                this.$Api.call('structure.importStructureUnits', oRequestParams)
                    .then(() => {
                        this.$notifySuccess({
                            title: this.$t('structure.import.success_title_units'),
                        });
                        this.$router.push({name: 'structure.structure'});
                    })
                    .catch(() => {
                        this.isImportButtonDisabled = false;
                    });
            },
            sendForm() {
                this.importUnits();
            },
        },
    };
</script>
