<template>
    <div class="login">
        <div class="login__header">
            <div class="logo" />
        </div>

        <form class="form form__login" @submit.prevent="resetPassword()">
            <div class="login__title title">{{ $t('reset_password.title') }}</div>
            <div class="login__text text text--left login__reset-password">{{ $t('reset_password.sub_title') }}</div>

            <div class="form__row form__row--indent-login">
                <label for class="form__label label login__label">
                    <span class="label__text">{{ $t('password') }}</span>
                    <input
                        type="password"
                        class="form-control"
                        autocomplete="new-password"
                        v-model="oForm.password"
                        ref="password"
                    />
                </label>
            </div>

            <div class="form__row form__row--indent-login">
                <label for class="form__label label login__label">
                    <span class="label__text">{{ $t('password_confirmation') }}</span>
                    <input
                        type="password"
                        class="form-control"
                        autocomplete="new-password"
                        v-model="oForm.password_confirmation"
                        ref="password"
                    />
                </label>
            </div>

            <div class="form__row form__row--indent-login">
                <button
                    class="btn btn--accent btn--login"
                    :class="{'disabled': isResetButtonLoading}"
                    :disabled="isResetButtonLoading"
                >
                    <template v-if="isResetButtonLoading">
                        <span class="btn--loading" />
                    </template>

                    {{ $t('reset_password.btn') }}
                </button>
            </div>

            <div class="login__info info">
                <div class="info__label info__label--login">
                    <a href="mailto:support@fmf.ru" class="info__label login__link">{{ $t('login_contact_with_support') }}</a>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                oForm: {
                    email: '',
                    token: '',
                    password: '',
                    password_confirmation: '',
                },
                isResetButtonLoading: false,
            };
        },
        created() {
            this.setParamsFromRoute();
        },
        methods: {
            setParamsFromRoute() {
                this.oForm.email = this.$route.query.email;
                this.oForm.token = this.$route.query.token;
            },
            resetPassword() {
                const errors = [];

                if (
                    this.oForm.password
                    && this.oForm.password_confirmation
                    && this.oForm.password !== this.oForm.password_confirmation
                ) {
                    errors.push(this.$t('person.errors.person_password_confirmation_required'));
                }

                if (errors.length) {
                    this.$notifyError({
                        title: this.$t('person.errors.title'),
                        text: errors,
                    });

                    return;
                }

                const oRequestParams = {
                    requestBody: this.oForm,
                };

                this.isResetButtonLoading = true;
                this.$Api.call('base.resetPassword', oRequestParams)
                    .then(() => {
                        this.$notifySuccess({
                            title: this.$t('reset_password.success.title'),
                        });

                        this.$router.push({name: 'login'});
                    })
                    .catch(() => {
                        this.isResetButtonLoading = false;
                    });
            },
        },
    };
</script>
