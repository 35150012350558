<template>
    <header class="header">
        <!-- nav -->
        <div
            class="shadow"
            :class="{'active': bShowShadow}"
            @click="changeVisibilityUserMobileMenu()"
        />

        <div class="header__menu menu">
            <button
                class="menu__toggle menu__toggle--deckstop"
                @click="changeVisibilityMenu"
            >
                <img src="/images/menu.svg" />
            </button>

            <div class="menu__logo logo">
                <a href="/">
                    <img :src="pageProperties.logoCompanyPath" alt />
                </a>
            </div>
        </div>

        <div class="header__title main__title title">{{ currentTitle }}</div>

        <div class="header__info">
            <!-- lang -->
            <!-- <div class="header__lang lang tip--header tip">
                <a class="tip__link" id="language">
                    <img :src="'/images/lang_' + $root.$i18n.locale +'.svg'" />
                </a>
                <div class="tip__box tip-box tip-box--bottom tip-box--arrow tip-box--arrow-top">
                    <a class="tip__block" @click="changeLocale('ru')">Русский</a>
                    <a class="tip__block" @click="changeLocale('en')">English</a>
                </div>
            </div> -->

            <!-- header-help
            <div class="header__help header-help">
                <a href=""><img src="/images/help.svg" /></a>
            </div>-->
            <!-- header-user -->

            <div class="header__user header-user tip tip--header tip--deckstop">
                <a class="tip__link tip__link--deckstop tip__link--header-user">
                    <span class="header-user__icon">
                        <img :src="photoSrc" />
                    </span>

                    <span class="tip-user__bio">{{ _.get($Auth.oPerson, 'short_name', '') }}</span>
                </a>

                <div
                    class="tip__box tip-box tip-box--nav tip-box--deckstop tip-box--bottom tip-box--arrow tip-box--arrow-top"
                    :class="{'active': bShowUserMenu}"
                >
                    <div class="tip-box__arrow tip-box__arrow--top" />

                    <router-link
                        to="/profile"
                        class="tip__block"
                        v-if="!$Auth.isAdmin()"
                    >
                        <img
                            class="tip-box__icon"
                            src="/images/tip-user-profile.svg"
                            alt=""
                        />
                        <span>{{ $t('my_profile') }}</span>
                    </router-link>

                    <router-link
                        to="/profile/notifications"
                        class="tip__block"
                        v-if="showMenuNotificationSettings"
                    >
                        <img
                            class="tip-box__icon"
                            src="/images/tip-noti.svg"
                            alt=""
                        />
                        <span>{{ $t('user_notifications_settings') }}</span>
                    </router-link>

                    <!-- <a class="tip__block" href="user.html">Мой профиль</a>
                    <a class="tip__block" href="ConfigureUserNotifications.html">Настройка уведомлений</a>
                    <a class="tip__block" href="changePassword.html">Смена пароля</a>-->
                    <div class="tip__block--separate" />
                    <a class="tip__block tip__block--logout" @click="logout()">
                        <img
                            class="tip-box__icon"
                            src="/images/tip-logout.svg"
                            alt=""
                        />
                        <span>{{ $t('logout') }}</span>
                    </a>
                </div>
            </div>

            <div class="header__user header-user tip tip--mobile">
                <a class="tip__link--mobile tip__link--header-user" @click="changeVisibilityUserMobileMenu()">
                    <span class="header-user__icon">
                        <img :src="photoSrc" />
                    </span>
                </a>

                <div
                    class="tip__box tip-box tip-box--mobile tip-box--slide-right menu-mobile"
                    :class="{'active': bShowUserMobileMenu}"
                >
                    <div class="menu-mobile__header menu-mobile__header--tip">
                        <span class="header-user__icon">
                            <img :src="photoSrc" />
                        </span>

                        <span class="tip-user__bio tip-user__bio--mobile">{{ _.get($Auth.oPerson, 'short_name', '') }}</span>

                        <button class="menu-mobile__toggle" @click="changeVisibilityUserMobileMenu()">
                            <img src="/images/menu-open-mob.svg" />
                        </button>
                    </div>

                    <router-link to="/profile" tag="span">
                        <a class="tip__block tip__block--mobile" @click="changeVisibilityUserMobileMenu()">
                            {{ $t('my_profile') }}
                        </a>
                    </router-link>

                    <router-link
                        to="/profile/notifications"
                        tag="span"
                        v-if="showMenuNotificationSettings"
                    >
                        <a class="tip__block tip__block--mobile" @click="changeVisibilityUserMobileMenu()">
                            {{ $t('user_notifications_settings') }}
                        </a>
                    </router-link>

                    <!-- <a class="tip__block tip__block--mobile" href="user.html">Мой профиль</a>
                    <a class="tip__block tip__block--mobile" href="ConfigureUserNotifications.html">Настройка уведомлений</a>
                    <a class="tip__block tip__block--mobile" href="changePassword.html">Смена пароля</a>-->

                    <a class="tip__block tip__block--mobile" @click="logout()">{{ $t('logout') }}</a>

                    <span class="tip__block--separate" />

                    <!-- <div class="tip__block tip__block--mobile">{{ $t('interface_language') }}</div>
                    <div class="tip__block--group">
                        <a class="tip__block tip__block--mobile" @click="changeLocale('ru')">
                            <img src="/images/lang_ru.svg" />
                        </a>
                        <a class="tip__block tip__block--mobile" @click="changeLocale('en')">
                            <img src="/images/lang_en.svg" />
                        </a>
                    </div> -->
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    // import UserService from '@/classes/UserService';

    // const oUserService = new UserService();

    export default {
        props: {
            bMenuShow: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                bShowUserMenu: false,
                bShowUserMobileMenu: false,
                bShowShadow: false,

                sDefaultAvatar: '/images/default-avatar.png',
            };
        },
        computed: {
            pageProperties() {
                return this.$store.state.pageProperties;
            },
            photoSrc() {
                if (this.$store.state.sPersonAvatar) {
                    return this.$store.state.sPersonAvatar;
                }

                if (this.$Auth.oPerson.photo_url) {
                    return this.$Auth.oPerson.photo_url;
                }

                return this.sDefaultAvatar;
            },
            showMenuNotificationSettings() {
                return this.$Auth.isEmployee() || this.$Auth.isManager();
            },
            currentTitle() {
                return this.$t(this.$route.meta.title);
            },
        },
        methods: {
            // changeLocale(locale) {
            //     if (locale !== window.localStorage.locale) {
            //         window.localStorage.setItem('locale', locale);
            //         this.$Api.setAcceptLanguage(locale);
            //
            //         oUserService.clearAllCache();
            //
            //         const requestParams = {
            //             segments: {
            //                 id: this.$Auth.oPerson.id,
            //             },
            //             requestBody: {
            //                 language: locale,
            //             },
            //         };
            //         this.$Api.call('base.setLang', requestParams).then(() => {
            //             this.$Auth.getPerson().then(() => {
            //                 // Reload page only for page title localization
            //                 this.$router.go(0);
            //             });
            //         });
            //     }
            // },
            logout() {
                this.$Api.call('base.logout')
                    .then(() => {
                        this.$Auth.logout();

                        window.location.replace('/login');
                    });
            },
            changeVisibilityMenu() {
                this.$parent.changeVisibilityMenu(!this.bMenuShow);
                this.$parent.changeVisibilityShadow(!this.bShowUserMobileMenu);

                document.body.classList.toggle('hidden-overflow-shadow');
            },
            changeVisibilityUserMobileMenu() {
                this.bShowUserMobileMenu = !this.bShowUserMobileMenu;
                this.changeVisibilityShadow(this.bShowUserMobileMenu);
            },
            changeVisibilityShadow(bool) {
                this.bShowShadow = bool;
            },
        },
    };
</script>
