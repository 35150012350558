import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        pageProperties: {
            accessDenied: false,
            title: '',
            logoCompanyPath: '',

            filter: {
                showOnDesktop: true,
                showShadow: false,
            },
        },

        planningPeriod: {
            active: false,
            menu_active: false,
            start_date: 0,
            end_date: 0,
            planning_year: 0,
            loaded: false,
        },

        transferSettings: {
            next_year_transfer_minimum_limit_of_current_year_days_off: 0,
            next_year_transfer_available_after_date: 0,
            current_year_vacations_off: 0,
            loaded: false,
        },

        sPersonAvatar: null,

        iModalCount: 0,
    },

    mutations: {
        setPersonAvatar(state, payload) {
            state.sPersonAvatar = payload;
        },
        enableScrollBody(state) {
            state.iModalCount--;

            if (!state.iModalCount) {
                document.body.style.overflow = 'auto';
            }
        },
        disableScrollBody(state) {
            state.iModalCount++;
            document.body.style.overflow = 'hidden';
        },
    },
});

export default store;
