<template>
    <fragment>
        <component :is="layout">
            <router-view />
        </component>

        <notifications
            group="app"
            position="top center"
            classes="n-light"
            :max="3"
            :width="500"
        />
    </fragment>
</template>

<script>
    export default {
        data() {
            return {
            };
        },

        computed: {
            layout() {
                return (this.$route.meta.layout || 'default') + '-layout';
            },
            title() {
                return this.$i18n.t(this.$route.meta.title);
            },
        },

        created() {
        },

        methods: {
        },
    };
</script>
