// Lodash является кастомным
require('@/lodash.custom.js');
/* Если появится необходимость установить
дополнительные методы в сборку lodash, необходимо:
    * Добавить дополнительные методы в package.json
    * Запустить команду npm run lodash-generate в консоли
    * После, сборка соберется заново
*/

import Vue from 'vue';
import i18n from '@/i18n';
import router from '@/routes';
import './assets/SASS/app.scss';

Vue.prototype._ = _;

import App from '@/views/app';

import Notifications from 'vue-notification';
Vue.use(Notifications);

Vue.prototype.$notify = (params) => {
    params.group = 'app';
    params.position = 'top center';
    params.duration = 12000;

    if (!params.type) {
        params.type = 'error';
    }

    if (_.isArray(params.text)) {
        params.text = params.text.join('<br>');
    }

    Vue.notify(params);
};

Vue.prototype.$notifyError = (params) => {
    params.type = 'error';
    Vue.prototype.$notify(params);
};

Vue.prototype.$notifySuccess = (params) => {
    params.type = 'success';
    Vue.prototype.$notify(params);
};

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);
import 'vue-multiselect/dist/vue-multiselect.min.css';

import vSelect from 'vue-select';
Vue.component('v-select', vSelect);

import VueMask from '@phoenix91/v-mask';
Vue.use(VueMask, {
    placeholders: {
        $: /[1-9]/,
    },
});

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
    OpenIndicator: {
        render: createElement => createElement('span', {class: {vs__triangle: true}}),
    },
});

import {Plugin} from 'vue2-storage';
Vue.use(Plugin);

import DefaultLayout from '@/views/layouts/default';
import EmptyLayout from '@/views/layouts/empty';

Vue.component('default-layout', DefaultLayout);
Vue.component('empty-layout', EmptyLayout);

import AccessDenied from '@/components/AccessDenied';
Vue.component('access-denied', AccessDenied);

import baseEndpoints from '@/endpoints';

import Api from '@/classes/Api';
Vue.prototype.$Api = new Api(baseEndpoints);

import Auth from '@/classes/Auth';
Vue.prototype.$Auth = new Auth();

import Fragment from 'vue-fragment';
Vue.use(Fragment.Plugin);

import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
Vue.use(AirbnbStyleDatepicker, {
    sundayFirst: false,
    dateLabelFormat: 'dddd, MMMM D, YYYY',
    days: Object.values(i18n.messages[i18n.locale].calendar.days),
    daysShort: Object.values(i18n.messages[i18n.locale].calendar.daysShort),
    monthNames: Object.values(i18n.messages[i18n.locale + ''].calendar.months),
    colors: {
        selected: '#023f88',
        inRange: '#a5b4e3',
        selectedText: '#fff',
        text: '#565a5c',
        inRangeBorder: '#a5b4e3',
        disabled: '#fff',
        hoveredInRange: '#a5b4e3',
    },
    texts: {
        cancel: i18n.messages[i18n.locale + ''].cancel,
        apply: i18n.messages[i18n.locale + ''].apply,
    },
});

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.use(DatePicker);

import modal from '@/components/modal';
Vue.component('modal', modal);

import ConfirmAction from '@/components/modal/ConfirmAction';
Vue.component('ConfirmAction', ConfirmAction);

import Vacation from '@/modules/vacations/module';
Vue.use(Vacation, {router: router, i18n: i18n, api: Vue.prototype.$Api});

import Structure from '@/modules/structure/module';
Vue.use(Structure, {router: router, i18n: i18n, api: Vue.prototype.$Api});

import Settings from '@/modules/settings/module';
Vue.use(Settings, {router: router, i18n: i18n, api: Vue.prototype.$Api});

import Help from '@/modules/help/module';
Vue.use(Help, {router: router, i18n: i18n, api: Vue.prototype.$Api});

import {DateTime as $DateTime, Settings as $LuxonSettings} from 'luxon';

$LuxonSettings.defaultLocale = 'ru';
$LuxonSettings.throwOnInvalid = true;
$LuxonSettings.defaultZone = 'system';

Vue.prototype.$DateTime = $DateTime;
Vue.prototype.$DateTime.now = $DateTime.local;

Vue.filter('formatFullDate', (value) => {
    return value ? $DateTime.fromISO(value).toFormat('dd.MM.yyyy') : value;
});

Vue.filter('formatFullDateTime', (value) => {
    return value
        ? $DateTime.fromFormat(value, 'yyyy-MM-dd HH:mm:ss', {zone: 'Etc/UTC'}).setZone($LuxonSettings.defaultZone.name).toFormat('dd.MM.yyyy HH:mm')
        : value;
});

import preloader from '@phoenix91/vue2-preloader';
Vue.use(preloader);

import store from '@/store/index';

const VueTruncate = require('vue-truncate-filter');
Vue.use(VueTruncate);

const app = new Vue({
    el: '#app',
    router,
    store,
    i18n,
    render: h => h(App),
});

export default app;
