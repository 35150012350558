import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';

import SettingsRoutes from './routes';
import SettingsI18nRu from './lang/ru';
import SettingsI18nEn from './lang/en';
import SettingsEndpoints from './endpoints';

const moduleName = 'settings';

export default {
    install: (Vue, options) => {
        if (options.router instanceof VueRouter) {
            options.router.addRoutes(SettingsRoutes);
        }

        if (options.i18n instanceof VueI18n) {
            const i18nRu = [];
            i18nRu[moduleName] = SettingsI18nRu;

            const i18nEn = [];
            i18nEn[moduleName] = SettingsI18nEn;

            options.i18n.mergeLocaleMessage('ru', i18nRu);
            options.i18n.mergeLocaleMessage('en', i18nEn);
        }

        if (options.api instanceof Vue.prototype.$Api.constructor) {
            options.api.addEndpoints(moduleName, SettingsEndpoints);
        }
    },
};
