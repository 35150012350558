import Vue from 'vue';

// TODO переместить в модуль structure
class UserService {
    constructor() {
        this.iOneHour = 60 * 60 * 1000;
//        this.iOneDay = 24 * 60 * 60 * 1000;
    }

    getUsers(sRole) {
        return new Promise((resolve) => {
            let sRoute = '';

            if (sRole === 'hr') {
                sRoute = 'structure.getHrManaged';
            }
            else if (sRole === 'head') {
                sRoute = 'structure.getSubordinates';
            }

            if (!sRole || !sRoute) {
                resolve([]);
                return;
            }

            // пользователь одновременно может иметь несколько ролей
            // поэтому в storage храним в разных переменных
            const storageKey = 'arUsersOf' + sRole[0].toUpperCase() + sRole.substring(1);

            if (!Vue.prototype.$storage.get(storageKey)) {
                Vue.prototype.$Api.call(sRoute)
                    .then(({data}) => {
                        Vue.prototype.$storage.set(storageKey, data, {ttl: this.iOneHour});
                        resolve(data);
                    });
            }
            else {
                resolve(Vue.prototype.$storage.get(storageKey));
            }
        });
    }

    getHeads(sRole) {
        return new Promise((resolve) => {
            let sRoute = '';

            if (sRole === 'hr') {
                sRoute = 'structure.getHrHeads';
            }

            if (!sRole || !sRoute) {
                resolve([]);
                return;
            }

            // пользователь одновременно может иметь несколько ролей
            // поэтому в storage храним в разных переменных
            const storageKey = 'arHeadsOf' + sRole[0].toUpperCase() + sRole.substring(1);

            if (!Vue.prototype.$storage.get(storageKey)) {
                Vue.prototype.$Api.call(sRoute)
                    .then(({data}) => {
                        Vue.prototype.$storage.set(storageKey, data, {ttl: this.iOneHour});
                        resolve(data);
                    });
            }
            else {
                resolve(Vue.prototype.$storage.get(storageKey));
            }
        });
    }

    clearAllCache() {
        Vue.prototype.$storage.remove('arUsersOfHr');
        Vue.prototype.$storage.remove('arUsersOfHead');

        Vue.prototype.$storage.remove('arHeadsOfHr');
    }
}

export default UserService;
