export default {
    settings: 'Справочный центр',
    info_name: 'Справочный центр',
    notifications_name: 'Настройки уведомлений',
    setting_name: 'Название',
    condition: 'Условие',
    type: 'Тип',

    menu: {
        help_center: 'Справочный центр',
    },

    help_instructions: 'Инструкции',
    help_using: 'Использование',
    help_profile_info: 'Ваш профиль',
    help_support_service: 'Служба технической поддержки',
    help_subtitle_planning: 'Планирование отпусков',
    help_subtitle_use: 'Использование отпусков',
    privacy_and_security: 'Конфиденциальность и безопасность',
    privacy_agreement: 'Соглашение о конфиденциальности',
    privacy_info: 'Copyright © 2019-2022 ООО “ЦЕНТР ИНФОРМАЦИОННЫХ ТЕХНОЛОГИЙ "ФМФ”',

    how_access_profile: 'Как попасть в свой аккаунт личный профиль?',
    how_access_profile_text: 'После авторизации в системе VACATION SOLUTION с помощью логина и пароля',
    how_access_profile_list_1: 'Навести курсор на свое ФИО в правом верхнем углу приложения',
    how_access_profile_list_2: 'В выпадающем меню необходимо нажать на раздел «Мой профиль». На данной странице содержится Ваша персональная информация',

    vacation_solution_instructions: 'Инструкции по работе с Vacation Solution',
    vacation_solution_training_materials: 'Инструкции по работе с Vacation Solution',
    instructions_employees: 'Инструкция по управлению ежегодным планированием для сотрудников (RU)',
    instructions_head_rus: 'Инструкция по управлению ежегодным планированием для руководителей (RU)',
    instructions_head_en: 'Инструкция по управлению ежегодным планированием для руководителей (EN)',
    instructions_hr: 'Пользовательская инструкция для HR',
    instructions_administrator_working_hours: 'Пользовательская инструкция для Администратора рабочего времени',

    instructions_use_head_ru: 'Инструкция по использованию отпусков для сотрудников (RU)',
    instructions_use_employee_ru: 'Инструкция по управлению отпусками подчиненных для руководителей (RU)',
    instructions_use_employee_en: 'Инструкция по управлению отпусками подчиненных для руководителей (EN)',

    vacation_solution_video_instructions: 'Видеоинструкции по работе с Vacation Solution',
    video_instructions_employees: 'Видео-инструкция для сотрудников (RU)',
    video_instructions_head_rus: 'Видео-инструкция для руководителей (RU)',
    video_instructions_head_en: 'Видео-инструкция для руководителей (EN)',
    video_instructions_hr: 'Инструкция для HR',

    update_profile: 'Как обновить информацию профиля Vacation Solution (например, имя, имя пользователя или электронный адрес)?',
    update_profile_text: 'Чтобы обновить информацию профиля, включая все содержимое раздела «Общая информация», а также e-mail, связанный с вашим аккаунтом, Вам необходимо связаться с HR службой Вашей компании и составить соответствующее обращение.',
    update_profile_contacts: 'Для изменения содержимого «Контактные данные»:',
    update_profile_contacts_list_1: 'Перейдите в раздел «Мой профиль»;',
    update_profile_contacts_list_2: 'Введите информацию в поле «Телефон» и нажмите',

    update_profile_photo: 'Как добавить или изменить фото профиля?',
    update_profile_photo_text: 'Чтобы добавить или изменить свое фото профиля:',
    update_profile_photo_list_1: 'Перейдите в раздел «Мой профиль»;',
    update_profile_photo_list_2: 'Наведите курсор на фотографию своего профиля;',
    update_profile_photo_list_3: 'Нажмите «Загрузить фото»;',
    update_profile_photo_list_4: 'Выберите место, из которого хотите импортировать фото;',
    update_profile_photo_list_5: 'Нажмите «Открыть».',

    how_get_out_profile: 'Как выйти из Vacation Solution?',
    how_get_out_profile_text: 'Чтобы выйти из своего профиля:',
    how_get_out_profile_list_1: 'Наведите курсор на иконку своего профиля;',
    how_get_out_profile_list_2: 'В выпадающем списке нажмите «Выход».',

    support_service_text: 'Любые вопросы можно задать нам через форму обратной связи в службу технической поддержки компании FMF. Сотрудники службы поддержки готовы помочь ежедневно с 9:00 до 18:00 по московскому времени. Рекомендуем изучить ответы на часто задаваемые вопросы на страницах раздела',
    support_service_text_link: '«Справочный центр»',
    support_service_form_title: 'Форма обратной связи:',
    support_service_form_name: 'Обращение от имени',
    support_service_form_name_label: 'Введите ФИО',
    support_service_form_data_label: 'Введите данные',
    support_service_form_appeal: 'Категория обращения',
    support_service_form_priority: 'Приоритет',
    support_service_form_priority_label: 'Приоритет обращения',
    support_service_form_theme: 'Тема обращения',
    support_service_form_theme_label: 'Введите тему обращения',
    support_service_form_textarea: 'Текст обращения',
    support_service_form_btn: 'Отправить обращение',
    support_service_category_plan_vacations: 'Планирование отпусков {year}',
    support_service_category_use_vacations: 'Использование отпусков',
    support_service_priority_high: 'Высокий',
    support_service_priority_medium: 'Средний',
    support_service_priority_low: 'Низкий',
    errors_title: 'Ошибка!',
    support_service_form_errors: {
        required_category: 'Выберите категорию обращения',
        required_priority: 'Выберите приоритет',
        required_subject: 'Введите тему обращения',
        required_message: 'Введите текст обращения',
    },
    support_service_form_success_message: 'Ваш вопрос успешно отправлен!',
    reference: 'Справка',
    statuses: {
        paid_vacation_title: 'Оплачиваемый отпуск',
        paid_vacation_text: 'Вы можете создать заявку на оплачиваемый отпуск при наличии доступного баланса отпусков на текущий календарный год. Сформированная заявка будет направлена руководителю на согласование.',
        vacation_without_saving_title: 'Отпуск без сохранения ЗП',
        vacation_without_saving_text: 'Вы можете создать заявку на отпуск без сохранения заработной платы на неограниченное количество дней. Заявка не влияет на баланс отпусков. Сформированная заявка будет направлена руководителю на согласование.',
        study_paid_vacation_title: 'Учебный оплачиваемый отпуск',
        study_paid_vacation_text: 'К заявке на оплачиваемый учебный отпуск необходимо прикрепить документы "Справка-вызов из учебного заведения" и "Заявление". Заявка не влияет на баланс отпусков. Сформированная заявка будет направлена на согласование Вашему руководителю и специалисту HR.',
        study_unpaid_vacation_title: 'Учебный неоплачиваемый отпуск',
        study_unpaid_vacation_text: 'К заявке на неоплачиваемый учебный отпуск необходимо прикрепить документы "Справка-вызов из учебного заведения" и "Заявление". Заявка не влияет на баланс отпусков. Сформированная заявка будет направлена на согласование Вашему руководителю и специалисту HR.',
        pvtr_days_wedding_title: 'Дни ПВТР: Свадьба',
        pvtr_days_wedding_text: 'Вы можете создать заявку на оплачиваемое отсутствие в связи с проведением Вашей свадьбы на период до 3 рабочих дней (например, с пт по вт или с чт по пн, праздничные дни не включаются). Заявка не влияет на баланс отпусков. Сформированная Вами заявка будет направлена руководителю на согласование.',
        pvtr_days_funeral_title: 'Дни ПВТР: Похороны',
        pvtr_days_funeral_text: 'Вы можете создать заявку на оплачиваемое отсутствие в связи с похоронами близкого родственника (супруги, дети, родители, братья, сестры) на период до 3 рабочих дней (например, с пт по вт или с чт по пн, праздничные дни не включаются). Заявка не влияет на баланс отпусков. Сформированная заявка будет направлена руководителю на согласование.',
        pvtr_days_birth_title: 'Дни ПВТР: Рождение ребенка',
        pvtr_days_birth_text: 'Вы можете создать заявку на оплачиваемое отсутствие в связи с рождением ребенка (например, на день выписки) на 1 рабочий день. Заявка не влияет на баланс отпусков. Сформированная заявка будет направлена руководителю на согласование.',
        pvtr_days_relocation_title: 'Дни ПВТР: Переезд',
        pvtr_days_relocation_text: 'Вы можете создать заявку на оплачиваемое отсутствие в связи с переездом на 1 рабочий день. Заявка не влияет на баланс отпусков. Сформированная заявка будет направлена руководителю на согласование.',
        blood_donation_title: 'Донорские дни',
        blood_donation_text: 'К заявке на донорский день необходимо прикрепить документ, подтверждающий сдачу крови. Вы можете создать заявку на период до 2 календарных дней (одновременно или раздельно по 1 дню). Заявка не влияет на баланс отпусков. Сформированная заявка будет направлена руководителю на согласование.',
        vacation_disabled_child_title: 'Доп. выходные по уходу за ребенком-инвалидом',
        vacation_disabled_child_text: 'Право на доп. выходные по уходу за ребенком-инвалидом предоставляется ежемесячно. К заявке необходимо прикрепить документы "Справка от второго родителя" и "Заявление". Заявка создается на период до 4 рабочих дней строго в месяце отсутствия без переноса на следующий месяц. Сформированная заявка будет направлена на согласование Вашему руководителю и специалисту HR.',
        vacation_high_radiation_zone_title: 'Доп.отпуск за нахождение в зоне высокой радиации',
        vacation_high_radiation_zone_text: 'Пострадавшие от радиационного воздействия вследствие аварии на Чернобыльской АЭС имеют право на дополнительный отпуск. Те, кто жили в зоне радиоактивного загрязнения в период с 26.04.1986, могут взять отпуск на 14 календарных дней, а с 02.12.1995 — на 7 календарных дней.',
    },
};
