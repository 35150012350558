<template>
    <fragment>
        <MainHeader
            :has-tabs="showTabs"
            :tabs="filterPermissionTabs"
            :initial-tab="selectedTab"
            @select-tab="selectTab"
        >
            <template v-slot:mainNotification>
                <slot name="mainNotification" />
            </template>

            <template v-slot:headerContent>
                <slot name="headerContent" />
            </template>
        </MainHeader>

        <div class="main__body content">
            <div class="tabs-body" v-if="showTabs">
                <div
                    v-for="(tab, index) in filterPermissionTabs"
                    :key="index"
                    :class="['tab-panel', { active: tab.name === selectedTab }]"
                >
                    <slot :name="tab.name" />
                </div>
            </div>
            <div v-else>
                <slot name="contentBody" />
            </div>

            <slot name="additional" />
        </div>

        <slot name="contentPost" />
    </fragment>
</template>

<script>
    import filterObject from '@/utils/filter-object';
    import MainHeader from '@/components/main/MainHeader';

    export default {
        components: {
            MainHeader,
        },
        props: {
            showTabs: {
                type: Boolean,
                default: false,
            },
            /**
             * Пример содержимого
            tabs: {
                head: {
                    heading: 'название вкладки',
                    component: 'название компонента для вкладки, если есть',
                    name: 'head, по нему выводятся содержимое вкладок, если нет 'component' (так же используется для initialTab, selectedTab для инициализации и переключения)',
                    hasPermissions: 'Boolean, разрешение на показ вкладки',
                    url: 'часть для подставления в router: '/vacations/plan?tab= url' ',
                    statistics: 'количество заявок в скобках у табов'
                },
            },
            */
            tabs: {
                type: Object,
                default: () => {},
            },
            initialTab: {
                type: String,
                default: '',
            },
            considerPermissions: {
                type: Boolean,
                default: false,
            },

        },
        data() {
            return {
                selectedTab: this.initialTab || '',
            };
        },
        computed: {
            filterPermissionTabs() {
                return this.considerPermissions ? filterObject(this.tabs, 'hasPermissions', true) : this.tabs;
            },
        },
        methods: {
            selectTab(name) {
                this.selectedTab = name;
                this.$emit('show-tab', name);
            },
        },
    };
</script>
