const endpoints = [
    // {
    //     name: 'setLang',
    //     method: 'get',
    //     path: '/users/:id',
    // },
    {
        name: 'getSelfUser',
        method: 'get',
        path: '/user',
    },
    {
        name: 'uploadUserPhoto',
        method: 'post',
        path: '/company-structure/persons/:id/photo',
    },
    {
        name: 'deletePhoto',
        method: 'delete',
        path: '/company-structure/persons/:id/photo',
    },
    {
        name: 'getNotificationsSettings',
        method: 'get',
        path: '/company-structure/persons/:id/notification-settings',
    },
    {
        name: 'updateNotificationsSettings',
        method: 'patch',
        path: '/company-structure/persons/:id/notification-settings',
    },

    //--------------------------------------------------

    {
        name: 'getBaseWorkCalendar',
        method: 'get',
        path: '/work-schedules/work-calendar',
    },
    {
        name: 'login',
        method: 'post',
        path: '/auth/login',
    },
    {
        name: 'logout',
        method: 'post',
        path: '/auth/logout',
    },
    {
        name: 'recover',
        method: 'post',
        path: '/auth/forgot-password',
    },
    {
        name: 'recoverPassword',
        method: 'post',
        path: '/auth/reset-password',
    },
    {
        name: 'resetPassword',
        method: 'post',
        path: '/auth/reset-password',
    },
    {
        name: 'getCurrentPerson',
        method: 'get',
        path: '/company-structure/persons/current',
    },
];

export default endpoints;
