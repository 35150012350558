<template>
    <div>
        <modal @hide="hide" size="small">
            <div class="modal__header">
                <h3 class="modal__title title">{{ params.title }}</h3>
            </div>

            <div class="modal__body">
                <div class="modal__info info" v-if="params.additionalInfoComponent">
                    <component :is="params.additionalInfoComponent" v-bind="params.additionalInfoComponentParams" />
                </div>

                <div
                    class="block"
                    v-if="params.text"
                    v-html="params.text"
                />

                <div v-if="params.showCommentField">
                    <label class="label">
                        <span class="label__text label__text--top">
                            {{ $t('comment') }} <sup>*</sup>
                        </span>

                        <textarea class="textarea" v-model="comment" />
                    </label>
                </div>
            </div>

            <div class="modal__footer">
                <div class="modal__btns">
                    <button
                        v-if="!params.hideButton"
                        type="button"
                        class="btn btn--accent"
                        :class="{'disabled': params.loading}"
                        :disabled="params.loading"
                        @click="confirmed"
                    >
                        <template v-if="params.loading">
                            <span class="btn--loading" />
                        </template>

                        {{ $t('confirm') }}
                    </button>
                    <button
                        v-else
                        type="button"
                        class="btn btn--accent"
                        @click="hide"
                    >
                        {{ $t('close') }}
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    const ConfirmAction = {
        props: {
            params: {
                type: Object,
                default() {
                    return {
                        title: null,
                        text: null,
                        showCommentField: false,
                        loading: false,
                        hideButton: false,
                        // TODO подумать над необходимостью dataBus
                        dataBus: null,
                        additionalInfoComponent: null,
                        additionalInfoComponentParams: null,
                    };
                },
            },
        },
        data() {
            return {
                comment: '',
            };
        },
        methods: {
            hide() {
                this.$emit('hide');
            },
            confirmed() {
                if (this.params.showCommentField) {
                    const sComment = this.comment.trim();

                    if (!sComment) {
                        this.$notifyError({
                            text: this.$t('comment_required'),
                        });

                        return;
                    }

                    if (sComment.length < 5) {
                        this.$notifyError({
                            text: this.$t('too_short_comment'),
                        });

                        return;
                    }

                    this.params.loading = true;
                    this.$emit('confirmed', {comment: sComment, dataBus: this.params.dataBus});
                }
                else {
                    this.params.loading = true;
                    this.$emit('confirmed', {comment: this.comment, dataBus: this.params.dataBus});
                }
            },
        },
    };

    const ConfirmActionProperty = {
        data() {
            return {
                ConfirmActionProperty: {
                    show: false,
                    params: {
                        title: null,
                        text: null,
                        hideButton: false,
                        showCommentField: false,
                        loading: false,
                        dataBus: null,
                        additionalInfoComponent: null,
                        additionalInfoComponentParams: null,
                    },
                    confirmedFunction: () => {},
                },
            };
        },
        methods: {
            showConfirmActionModal(params = {}) {
                this.ConfirmActionProperty.show = true;

                this.ConfirmActionProperty.params = {
                    title: params.title || this.$t('confirm_action'),
                    text: params.text || null,
                    hideButton: params.hideButton || false,
                    showCommentField: params.showCommentField || false,
                    loading: params.loading || false,
                    dataBus: params.dataBus || null,
                    additionalInfoComponent: params.additionalInfoComponent || null,
                    additionalInfoComponentParams: params.additionalInfoComponentParams || null,
                };

                if (this._.isFunction(params.confirmedFunction)) {
                    this.ConfirmActionProperty.confirmedFunction = params.confirmedFunction;
                }
            },
            hideConfirmActionModal() {
                this.ConfirmActionProperty.show = false;
            },
            setLoadingButton() {
                this.ConfirmActionProperty.params.loading = true;
            },
            unsetLoadingButton() {
                this.ConfirmActionProperty.params.loading = false;
            },
        },
    };

    export default ConfirmAction;

    export {ConfirmAction, ConfirmActionProperty};
</script>
