export default {
    home: 'Главная',
    settings: 'Настройки',

    login: 'Логин',
    login_button: 'Вход',
    logout: 'Выход',
    login_recover: 'Забыли пароль?',

    login_title: 'Добро пожаловать в Vacation Solution',
    enter_login: 'Введите логин',
    enter_password: 'Введите пароль',
    enter_email: 'Введите e-mail',
    access_denied: 'Доступ запрещен',
    login_policy: {
        part1: 'Нажимая кнопку «Войти», Вы соглашаетесь с',
        part2: 'политикой обработки персональных данных',
    },
    login_contact_with_support: 'Связаться со службой технической поддержки',
    login_cookie_info: 'Мы используем файлы cookie, разработанные нашими специалистами, для анализа событий на нашем портале, что позволяет нам улучшать взаимодействие с пользователями и обслуживание. Продолжая просмотр страниц нашего сайта, Вы принимаете условия его использования.',
    login_cookie_agree: 'Я согласен',

    recover_password: {
        recover: 'Восстановление пароля',
        btn: 'Отправить ссылку',
        title: 'Восстановление пароля для входа в Vacation Solution',
        text: 'Пожалуйста, введите Ваш адрес электронной почты для получения ссылки на форму создания нового пароля.',
        success: {
            title: 'Заявка успешно создана',
            text: 'На вашу почту отправлена ссылка на форму создания нового пароля.',
        },
    },

    reset_password: {
        reset: 'Создание нового пароля',
        btn: 'Сохранить пароль',
        title: 'Добро пожаловать в Vacation Solution',
        sub_title: 'Задайте пароль для вашей учетной записи',
        success: {
            title: 'Пароль успешно сохранен',
        },
    },
    comment_required: 'Поле комментарий обязательно для заполнения',

    content_of_the_page: 'Содержание страницы',

    confirm_action: 'Подтвердите действие',
    cancel_request: 'Отменить заявку',
    reject_request: 'Отклонить заявку',
    confirm_request: 'Согласовать заявку',
    delete_request: 'Удалить заявку',

    // Users
    users: 'Сотрудники',
    user_add: 'Добавить сотрудника',
    user_edit: 'Редактировать сотрудника',
    departments_of_user: 'Департаменты сотрудника',
    user_role: 'Роль сотрудника',
    structure_user_view: 'Просмотр пользователя',
    person_role: 'Роль пользователя',
    comment: 'Комментарий',
    employee_information: 'Информация о сотруднике',
    position: 'Должность',
    department_hr: 'Отдел',
    department: 'Департамент',
    departments: 'Департаменты',
    common_information: 'Общая информация',
    user_profile: 'Профиль сотрудника',
    my_profile: 'Мой профиль',
    delegate: 'Делегирование',
    work_position: 'Должность',
    personnel_number: 'Табельный номер',

    // Buttons
    action: 'Действие',
    add: 'Добавить',
    adding: 'Добавление',
    edit: 'Редактировать',
    editing: 'Редактирование',
    save: 'Сохранить',
    saving: 'Сохранение',
    view: 'Просмотр',
    view_transfer: 'Просмотр переноса',
    viewing: 'Просматривание',
    loading: 'Загружается',
    delete: 'Удалить',
    cancel: 'Отменить',
    reject: 'Отклонить',
    confirm: 'Подтвердить',
    approve: 'Утвердить',
    reconcile: 'Согласовать',
    export: 'Экспорт',
    export_requests: 'Экспорт заявок',
    today: 'Сегодня',
    week: 'Неделя',
    month: 'Месяц',
    send_email: 'Отправить письмо',
    upload_photo: 'Загрузить фото',
    close: 'Закрыть',
    deselect: 'Снять выделение',
    import: 'Импортировать',
    importing: 'Импортируется',

    //
    basic_information: 'Базовая информация',
    employment_information: 'Информация о трудоустройстве',
    person_password: 'Пароль пользователя',
    change_password: 'Изменить пароль',
    not_found: 'Не найдено',
    not_chosen: 'Не выбрано',
    symbolic_code: 'Символьный код',
    title: 'Название',
    title_en: 'Название (на английском)',
    employee: 'Сотрудник',
    role: 'Роль',
    id: 'ID',
    email: 'Email',
    full_name_ru: 'ФИО',
    last_name: 'Фамилия',
    first_name: 'Имя',
    second_name: 'Отчество',
    full_name_en: 'ФИО (на английском)',
    last_name_en: 'Фамилия (на английском)',
    first_name_en: 'Имя (на английском)',
    second_name_en: 'Отчество (на английском)',
    recruitment_date: 'Дата приема на работу',
    sex: 'Пол',
    birth_date: 'Дата рождения',
    add_fullname_declinations: 'Добавить склонения ФИО',
    add_position_declinations: 'Добавить склонения должности',
    is_not_employee: 'Не сотрудник',
    is_hr: 'HR',
    is_head: 'Менеджер',
    phone: 'Телефон',
    name: 'Имя',
    actions: 'Действия',
    apply: 'Применить',
    reset: 'Сбросить',
    hide_filter: 'Скрыть фильтр',
    show_filter: 'Показать фильтр',
    set_filter: 'Фильтр',
    password: 'Пароль',
    password_confirmation: 'Подтверждение пароля',
    remember_me: 'Запомнить меня',
    page_not_found: 'Страница не найдена',
    page_not_found_description: 'Не удалось найти запрашиваемую вами страницу. Возможно она была удалена или еще не создана. Попробуйте воспользоваться ссылками ниже:',
    page_access_denied: 'Отказано в доступе',
    page_access_denied_description: 'У Вас нет доступа к запрашиваемой информации. Но Вы всегда можете воспользоваться ссылками ниже:',
    year: 'Год',
    year_lowercase: 'год',
    select_year: 'Выберите год',
    choose_file: 'Выберите файл',
    choose_action: 'Выберите действие',
    attachment: 'Прикрепить файл',
    not_set: 'Не установлено',
    choose_dates: 'Выберите даты',
    language: 'Язык',
    languages: {
        en: 'English',
        ru: 'Русский',
    },
    interface_language: 'Язык интерфейса',
    yes: 'Да',
    no: 'Нет',
    admin: 'Администратор',
    general_data: 'Общие данные',
    birthday: 'День рождения',
    contact_details: 'Контактные данные',
    attach_statement: 'Прикрепить документ',
    attach_supporting_documents: 'Прикрепить подтверждающие документы',
    days: 'Дней',
    period: 'Дата',
    filters: 'Фильтры',

    calendar: {
        days: {
            monday: 'Понедельник',
            tuesday: 'Вторник',
            wednesday: 'Среда',
            thursday: 'Четверг',
            friday: 'Пятница',
            saturday: 'Суббота',
            sunday: 'Воскресение',
        },
        daysShort: {
            monday: 'Пн',
            tuesday: 'Вт',
            wednesday: 'Ср',
            thursday: 'Чт',
            friday: 'Пт',
            saturday: 'Сб',
            sunday: 'Вс',
        },
        months: {
            january: 'Январь',
            february: 'Февраль',
            march: 'Март',
            april: 'Апрель',
            may: 'Май',
            june: 'Июнь',
            july: 'Июль',
            august: 'Август',
            september: 'Сентябрь',
            october: 'Октябрь',
            november: 'Ноябрь',
            december: 'Декабрь',
        },
        monthsShort: {
            january: 'Янв',
            february: 'Фев',
            march: 'Мар',
            april: 'Апр',
            may: 'Май',
            june: 'Июн',
            july: 'Июл',
            august: 'Авг',
            september: 'Сен',
            october: 'Окт',
            november: 'Ноя',
            december: 'Дек',
        },
    },

    legend: 'Условные обозначения',

    manager: 'Менеджер',

    roles: {
        employee: 'Сотрудник',
        head: 'Руководитель',
        hr: 'HR',
        admin: 'Админ',
        production: 'Работник производства',
        fired: 'Уволен',
    },

    user_notifications_settings: 'Настройка уведомлений',
    notification_settings_save_success_title: 'Сохранение завершено',
    notification_settings_save_success: 'Настройки уведомлений успешно сохранены',
    more: 'Еще',
    number_symbol: '№',
    request_number: '№ заявки',
    go_to_page: 'Перейти на страницу',
    employee_balance_sheet: 'Отчет о балансах сотрудников',

    report_option: 'Параметры запрашиваемого отчёта',
    report_option_filter: {
        report_date: 'Дата',
        enter_report_date: 'Введите дату',
        employee: 'ФИО сотрудника',
        enter_employee: 'Введите ФИО сотрудника',
        department: 'Департамент',
        enter_department: 'Введите департамент',
        manager: 'Менеджер',
        select_manager: 'Выберите менеджера',
        users_type: 'Тип сотрудников',
        all_users: 'Все сотрудники',
        errors: {
            title: 'Ошибка',
            report_date_invalid: 'Недопустимое значение даты',
        },
    },

    person: {
        add_success: {
            title: 'Пользователь успешно добавлен',
        },
        change_success: {
            title: 'Данные успешно изменены',
        },
        password_change_success: {
            title: 'Пароль успешно изменен',
        },
        errors: {
            title: 'Ошибка',
            person_last_name_ru_required: 'Введите фамилию пользователя',
            person_first_name_ru_required: 'Введите имя пользователя',
            person_email_required: 'Введите email пользователя',
            person_password_confirmation_required: 'Пароли не совпадают',
            person_role_type_required: 'Выберите роль пользователя',
            person_structure_unit_required: 'Выберите подразделение',
        },
    },

    empty_filter_result: 'Для выбранного фильтра результаты не найдены',
    too_short_comment: 'Минимальная длина комментария при отклонении 5 символов',
};
