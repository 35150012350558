import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';

import HelpRoutes from './routes';
import HelpI18nRu from './lang/ru';
import HelpI18nEn from './lang/en';
import HelpEndpoints from './endpoints';

const moduleName = 'help';

export default {
    install: (Vue, options) => {
        if (options.router instanceof VueRouter) {
            options.router.addRoutes(HelpRoutes);
        }

        if (options.i18n instanceof VueI18n) {
            const i18nRu = [];
            i18nRu[moduleName] = HelpI18nRu;

            const i18nEn = [];
            i18nEn[moduleName] = HelpI18nEn;

            options.i18n.mergeLocaleMessage('ru', i18nRu);
            options.i18n.mergeLocaleMessage('en', i18nEn);
        }

        if (options.api instanceof Vue.prototype.$Api.constructor) {
            options.api.addEndpoints(moduleName, HelpEndpoints);
        }
    },
};
