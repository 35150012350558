import Vue from 'vue';

const Settings = () => import('./views/settings/index');
const SettingsCommon = () => import('./views/settings/common/index');
const SettingsVacations = () => import('./views/settings/vacations/index');
const SettingsNotifications = () => import('./views/settings/notifications/index');

// eslint-disable-next-line no-unused-vars
function isEmployee() {
    return Vue.prototype.$Auth.isEmployee();
}

// eslint-disable-next-line no-unused-vars
function isManager() {
    return Vue.prototype.$Auth.isManager();
}

function isHr() {
    return Vue.prototype.$Auth.isHr();
}

function isAdmin() {
    return Vue.prototype.$Auth.isAdmin();
}

const routes = [
    {
        path: '/settings',
        name: 'settings.settings',
        components: {
            default: Settings,
        },
        meta: {
            title: 'settings.settings',
            middlewareAuth: true,
            showModal: false,
        },
    },
    {
        path: '/settings/common',
        name: 'settings.common_name',
        components: {
            default: SettingsCommon,
        },
        meta: {
            title: 'settings.common_name',
            middlewareAuth: true,
            showModal: false,
            checkAccessFunctions: {
                isAdmin,
            },
        },
    },
    {
        path: '/settings/vacations',
        name: 'settings.vacations_name',
        components: {
            default: SettingsVacations,
        },
        meta: {
            title: 'settings.vacations_name',
            middlewareAuth: true,
            showModal: false,
            checkAccessFunctions: {
                isAdmin,
                isHr,
            },
        },
    },
    {
        path: '/settings/notifications',
        name: 'settings.notifications_name',
        components: {
            default: SettingsNotifications,
        },
        meta: {
            title: 'settings.notifications_name',
            middlewareAuth: true,
            showModal: false,
            checkAccessFunctions: {
                isAdmin,
                isHr,
            },
        },
    },
];

export default routes;
