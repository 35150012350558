const endpoints = [
    {
        name: 'getCompanyStructure',
        method: 'get',
        path: '/company-structure',
    },
    {
        name: 'getStructureUnitNames',
        method: 'get',
        path: '/company-structure/structure-units',
    },
    {
        name: 'getStructureUnit',
        method: 'get',
        path: '/company-structure/structure-units/:id',
    },
    {
        name: 'addStructureUnit',
        method: 'post',
        path: '/company-structure/structure-units',
    },
    {
        name: 'editStructureUnit',
        method: 'patch',
        path: '/company-structure/structure-units/:id',
    },
    {
        name: 'importStructureUnits',
        method: 'post',
        path: '/company-structure/import/structure-units-file',
    },
    {
        name: 'deleteStructureUnit',
        method: 'delete',
        path: '/company-structure/structure-units/:id',
    },
    {
        name: 'getUserStructureUnit',
        method: 'get',
        path: '/company-structure/persons/:user_id/structure-units',
    },
    {
        name: 'getPersons',
        method: 'get',
        path: '/company-structure/persons',
    },
    {
        name: 'getAllPersons',
        method: 'get',
        path: '/company-structure/dictionary/persons',
    },
    {
        name: 'addPerson',
        method: 'post',
        path: '/company-structure/persons',
    },
    {
        name: 'getPerson',
        method: 'get',
        path: '/company-structure/persons/:person_id',
    },
    {
        name: 'editPerson',
        method: 'patch',
        path: '/company-structure/persons/:person_id',
    },
    {
        name: 'importPersons',
        method: 'post',
        path: '/company-structure/import/persons-file',
    },
    {
        name: 'getPersonRoles',
        method: 'get',
        path: '/company-structure/persons/:person_id/roles',
    },
    {
        name: 'addRoleToPerson',
        method: 'post',
        path: '/company-structure/persons/:person_id/roles',
    },
    {
        name: 'deletePersonRole',
        method: 'delete',
        path: '/company-structure/persons/:person_id/roles/:id',
    },
    {
        name: 'getAllPersonsRoles',
        method: 'get',
        path: '/company-structure/persons/role-types',
    },
    {
        name: 'getSubordinates',
        method: 'get',
        path: '/company-structure/manager-subordinates',
    },
    {
        name: 'getHrManaged',
        method: 'get',
        path: '/company-structure/hr-managed',
    },
    {
        name: 'getHrHeads',
        method: 'get',
        path: '/company-structure/dictionary/managers',
    },
    {
        name: 'changePassword',
        method: 'patch',
        path: '/company-structure/persons/:person_id/password',
    },
    {
        name: 'getDeclinations',
        method: 'get',
        path: '/company-structure/declinations',
    },
];

export default endpoints;
