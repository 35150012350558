const endpoints = [
//--------------------------vacation-settings------------------------//
    {
        name: 'getTransferSettings',
        method: 'get',
        path: '/vacations/transfers/settings',
    },
    {
        name: 'updatetTransferSettings',
        method: 'patch',
        path: '/vacations/transfers/settings',
    },
    {
        name: 'getPlanSettings',
        method: 'get',
        path: '/vacations/plan/settings',
    },
    {
        name: 'updatePlanSettings',
        method: 'patch',
        path: '/vacations/plan/settings',
    },
    {
        name: 'startPlanningPeriod',
        method: 'get',
        path: '/vacations/plan/start-planning-period',
    },
    {
        name: 'endEmployeePlanningPeriod',
        method: 'get',
        path: '/vacations/plan/complete-employee-planning-period',
    },
    {
        name: 'endPlanningPeriod',
        method: 'get',
        path: '/vacations/plan/complete-planning-period',
    },
    {
        name: 'startAutoPlanning',
        method: 'get',
        path: '/vacations/plan/start-auto-planning',
    },
    {
        name: 'getVacationMailTemplates',
        method: 'get',
        path: '/notification-templates',
    },
    {
        name: 'getVacationMailTemplate',
        method: 'get',
        path: '/notification-templates/:id',
    },
    {
        name: 'sendPlanningPeriodStartedNotification',
        method: 'get',
        path: '/vacations/plan/send-planning-period-started-notification',
    },
    {
        name: 'sendEmployeePlanningPeriodCompleteNotification',
        method: 'get',
        path: '/vacations/plan/send-employee-planning-period-complete-notification',
    },
    {
        name: 'getPlanningPeriodHistory',
        method: 'get',
        path: '/vacations/plan/planning-period-history',
    },
    {
        name: 'getCompanySettings',
        method: 'get',
        path: '/company-structure/settings',
    },
    {
        name: 'getPublicCompanySettings',
        method: 'get',
        path: '/company-structure/settings/public',
    },
    {
        name: 'editCompanySettings',
        method: 'patch',
        path: '/company-structure/settings',
    },
    {
        name: 'uploadCompanyLogo',
        method: 'post',
        path: '/company-structure/settings/logo',
    },
];

export default endpoints;
